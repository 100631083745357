import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityInterface } from '../../../../../core/interfaces/activity/activity.interface';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { NewUnitModel } from '../../../../../core/models/units/new-unit-model';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapHelper } from 'src/app/core/heplers/map.helper';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { StockResourceModel } from '../../../../../core/models/stock/stock-resource.model';
import { PlanModel } from '../../../../../core/models/company/plan.model';
import { PlanTypeModel } from '../../../../../core/models/company/plan.type.model';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { DatePipe } from '@angular/common';

export const DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'l, LTS',
    },
    display: {
      dateInput: 'MMM DD YYYY, HH:mm',
      monthYearLabel: 'MM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}

@Component({
    selector: 'fap-activities-add-edit-layout',
    templateUrl: './fap-activities-add-edit-layout.component.html',
    styleUrls: ['./fap-activities-add-edit-layout.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapActivitiesAddEditLayoutComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public isEditMode = false;

    @Input()
    public isPreviewMode = false;

    @Input()
    public activity: ActivityModel;

    @Input()
    public activityTypes: Array<ActivityTypeModel>;

    @Input()
    public planTypes: Array<PlanTypeModel>;

    @Input()
    public activityId: number;

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Input()
    public isSubmitDisabled = false;

    @Input()
    public equipments: Array<any> = [];

    @Input() public isBaselineActivity: boolean = false;

    @Input() public baselineActivityId: number = null;

    @Input() public activities: Array<ActivityModel> = [];

    @Input() public persons;

    @Output()
    public submitEquipment: EventEmitter<{}> = new EventEmitter();

    @Output()
    public enabledEdit: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteEquipmentType: EventEmitter<number> = new EventEmitter();
    @Input()
    public title: string;
    @Output()
    public deleteActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public submitActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public updateActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public scrolledDownActivities: EventEmitter<any> = new EventEmitter();
    @Output()
    public filterActivities: EventEmitter<any> = new EventEmitter();
    @Output()
    public closeModal: EventEmitter<any> = new EventEmitter();
    @Output()
    public baselineActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public triggerBaselineActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public submitBaselineActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public updateBaselineActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public deleteBaselineActivity: EventEmitter<any> = new EventEmitter();
    public personList: any;
    public untiltimeVal: any;
    public timestamptimeVal: any;
    public minDate;
    public maxDate;
    public activitysCoords: any;
    public coordsString: string;
    public isPerson = false;
    public assignedTo;
    public initialActivityForm: ActivityInterface;
    private subscriptions: Array<Subscription> = [];
    public activityForm: FormGroup;
    public lotGroup: any = [];
    public entityRelation: AttributeEntityEnum = AttributeEntityEnum.Activity;
    public enityRelations: AttributeEntityEnum.Activity =
        AttributeEntityEnum.Activity;
    
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
    public attachableEquipments: Array<EquipmentModel> = [];
    public mainEquipments: Array<EquipmentModel> = [];
    public getMorePersons = true;
    public latest = false;
    public activityTypeId: any;
    public activityType: ActivityTypeModel;
    
    public activityTypeForm: UntypedFormGroup;
    public addActivityTypeModalButtonPrimaryInterface: FapModalButtonInterface;
    public addActivityTypeModalButtonSecondaryInterface: FapModalButtonInterface;
    public activityGroupId: any;
    public addActivityGroupModalButtonPrimaryInterface: FapModalButtonInterface;
    public addActivityGroupModalButtonSecondaryInterface: FapModalButtonInterface;

    public addTransactionButtonPrimaryInterface: FapModalButtonInterface;
    public addTransactionButtonSecondaryInterface: FapModalButtonInterface;
    @ViewChild('addActivityTypeModal')
    public addActivityTypeModal: FapModalComponent;
    @ViewChild('addActivityGroupModal')
    public addActivityGroupModal: FapModalComponent;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    @ViewChild('addEditNameTranslationsModal')
    public addEditNameTranslationsModal: FapModalComponent;
    @ViewChild('addEditGroupTranslationsModal')
    public addEditGroupTranslationsModal: FapModalComponent;
    @ViewChild('addEditItemModal')
    public addEditItemModal: FapModalComponent;
    @ViewChild('addEditResourceModal')
    public addEditResourceModal: FapModalComponent;
    @ViewChild('addEditPlanModal')
    public addEditPlanModal: FapModalComponent;
    @ViewChild('addEditActivityModal')
    public addEditActivityModal: FapModalComponent;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public isEdit = false;
    public selectedUnit: UnitModel;
    public limit = 50;
    public units: Array<UnitModel> = [];
    public unitForm: UntypedFormGroup;
    public selectedUnitId: number;
    public farmForm: UntypedFormGroup;
 
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  public products = [];
  public personToken: { offset: number; limit: number, search: string; } = null;
  public activityEquipments:Array<any> = [];
  public mediaUrl = environment.mediaUrl
  public nextToken: { offset: number; limit: number; group: string; search: string} = null;
    public usedEquipment;
    public attachedEquipment1;
    public attachedEquipment2;
    public transactions;
    public transactionIds = [];
    
    public resourcesDoc;
    public activityFarms: Array<FarmModel> = [];
    public activityDocFarm: number = null;
    public documentFarm: number = null;
    public localDocId: any;
    public localPDocId: any;
    public r_items: Array<any> = [];
    public p_items: Array<any> = [];
    public currentUser:number;
    public localLots = [];
    public prefix = 'ACTIVITY_'
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    public entityInfo: any;
    @Input() public formTypes:any = [];
    @ViewChild('fileUploader') fileUploader:ElementRef;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    public translation = null;
    public nameT = null;
    public groupNameT = null;
    public formType;
    public formFields;
    public label: string;
    public itemForm: UntypedFormGroup;
    public stockItems: Array<StockItemModel> = [];
    public entries = [];
    public max = 9999;
    public entryProductId;
    public selectedEntry = null;
    public stockUnit;
    public availableVAT = [];
    public closeCombo: boolean = true;
    public plans: Array<PlanModel> = [];
    public plan: PlanModel = null;
    public planId: number = null;
    @ViewChild('addEditSchedulerModal') addEditSchedulerModal: FapModalComponent;
    public getMore = true;
    public schedulers = [];
    public schedulerId = null;
    public scheduler = null;
    public objectId = null;
    public activityImages:any = [];
    public setTrigger = false;
    public parent = null;

    public isNewDoc: boolean = null;
    public docId: number = null;
    public docType: string = null;
    public isModalView: boolean = null;

    constructor(
        public formBuilder: UntypedFormBuilder,
        public toastr: ToastrService,
        public navService: NavService,
        public translateService: TranslateService,
        private router: Router,
        public mapService: MapService,
        public companyService: CompanyService,
        public globalRegistryService: GlobalRegistryService,
        public activityService: ActivityService,
        public globalRegistry: GlobalRegistryService,
        public stockService: StockService,
        public confirmModalService: ConfirmModalService,
        public typesService: TypesService,
        public unitService: UnitService,
        public route: ActivatedRoute,
        public userService: UserService,
        public widgetsService: WidgetsService,
        public cacheService: CacheResolverService,
        public attributeService: AttributeService,
        public sensorService: SensorService,
        private translate: TranslateService
    ) {
        this.initForm();
        this.initItemForm();
        this.initActivityTypeForm();
        this.initAddActivityTypeModalButtons();
        this.personList = globalRegistry.systemData.persons;
        this.userService.getCurrentUser.subscribe(data => {
            this.currentUser = data.id
        });
    }

    setLocalLot(val) {
        localStorage.setItem('lots', val.value.toString());
        this.localLots = val.value;
    }

    public getFormTypeById(typeId: number) {
        if(!typeId) return
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url+typeId);
        this.typesService.getFormTypeById(typeId).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results;
        })
      }

      public updateAttributes() {
        if(this.entityInfo) {
            this.attributeService.updateForm(this.entityInfo.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

    getSchedulers() {
        let url = this.sensorService.getUrl('scheduler/');
        this.cacheService.delete(url);
        this.sensorService.getSchedulers().subscribe((data) => {
          console.log(data);
          this.schedulers = data.body.results;
        });
    }

    getActivityFarm(lotIds) {
        console.log(lotIds);
        lotIds = lotIds.filter(item => typeof item === 'object').map(obj => obj.id);
        this.activityFarms = [];
        const localFarms = [];
        lotIds.forEach(element => {
            const lot = this.lots.filter(l => {
                if(l.id === element) {
                    return l;
                }
            });
            console.log(lot);
            localFarms.push(lot[0].farm);
        });
        const activityFarms = [...new Set(localFarms)];
        console.log(activityFarms);

            this.activityDocFarm = activityFarms[0];
            // this.goToProductionBill();
            this.getResourceInfo();
        
    }

    setLocalVal(control, val) {
        localStorage.setItem(control, val.value)
    }
    setInputVal(control, val) {
        localStorage.setItem(control, val.target.value)
    }

    clearLocalVals() {
        localStorage.removeItem('activityType');
        localStorage.removeItem('group');
        localStorage.removeItem('title');
        localStorage.removeItem('lots');
        localStorage.removeItem('started');
        localStorage.removeItem('ended');
        localStorage.removeItem('assignedTo');
        localStorage.removeItem('usedEquipment');
        localStorage.removeItem('attachedEquipment1');
        localStorage.removeItem('attachedEquipment2');
        localStorage.removeItem('info');
    }

    public initAddActivityTypeModalButtons(): void {
        const _this: FapActivitiesAddEditLayoutComponent = this;

        this.addActivityTypeModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.activityTypeSubmit();
            },
            text: this.translateService.instant('save'),
        };

        this.addActivityTypeModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addActivityTypeModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    activityTypeEdit(activityType: ActivityTypeModel) {
        this.activityTypeId = activityType.id;
        this.activityType = activityType;
        console.log(activityType)
        this.initActivityTypeForm();
        this.showActivityTypeModal();
    }

    addNewActivityGroup() {
        this.activityGroupId = null;
        this.showActivityGroupModal();
    }

    activityTypeDelete(activityType) {
        this.deleteActivityType(activityType.id);
    }

    public activityTypeSubmit() {
        if (this.activityTypeForm.invalid) {
            Object.keys(this.activityTypeForm.controls).forEach(
                (controlName: string) =>
                    this.activityTypeForm.controls[controlName].markAsTouched()
            );
            return;
        }
        console.log(this.activityTypeForm.value);
        // return
        if (this.activityTypeId) {
            this.editActivityType(
                this.activityTypeForm.value,
                this.activityTypeId
            );
            this.addActivityTypeModal.hideModal();
        } else {
            this.addActivityType(this.activityTypeForm.value);
            this.addActivityTypeModal.hideModal();
        }
        this.activityType = null;
        this.activityTypeForm.reset();
        this.closeCombo = true;
    }

    public addActivityType(activity: FormData): void {
        this.typesService.addActivityType(activity).subscribe((data) => {
            this.toastr.success(
                this.translateService.instant('activities.createActivityType')
            );
            this.activityTypes.unshift(data.model);
            if(activity['entity_form']) {
                this.objectId = activity['entity_form'];
                this.getFormTypeById(activity['entity_form']);
            }
            this.activityForm.get('activityType').setValue(data.model.id)
            this.closeCombo = true;
        }, (error) => {
            this.toastr.error(error.error.results.error);
        });
    }

    public reloadActivityTypes() {
        const url = this.typesService.getUrl('activity/');
        this.cacheService.delete(url);
        this.typesService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
            this.activityTypes = types.model;
        })
    }

    public editActivityType(activity: FormData, activityTypeId: number): void {
        this.typesService
            .updateActivityType(activity, activityTypeId)
            .subscribe((data) => {
                this.toastr.success(
                    this.translateService.instant('activities.updateActivityType')
                );
                const index = this.activityTypes.findIndex(type => type.id === activityTypeId);
                if (index !== -1) {
                    this.activityTypes[index] = data.model;
                }
                this.activityForm.get('activityType').setValue(data.model.id)
                // this.reloadActivityTypes()
                if(activity['entity_form']) {
                    this.objectId = activity['entity_form'];
                    this.getFormTypeById(activity['entity_form']);
                }
                this.closeCombo = true;
            }, (error) => {
                this.toastr.error(error.error.results.error);
            });
    }

    public deleteActivityType(activityTypeId: number): void {
        this.typesService
                        .deleteActivityType(activityTypeId)
                        .subscribe(() => {
                            this.activityService.getActivitiesList.next();
                            this.toastr.success(
                                this.translateService.instant(
                                    'activities.deleteActivityTypeSuccessfully'
                                )
                            );
                            this.reloadActivityTypes();
                        });
    }

    onDeletePlan(plan) {
        console.log(plan);
        this.toastr.error('A plan can not be deleted');
        return
        // this.companyService.deleteplan(plan.id).subscribe(data => {
        //     if(this.activityForm.get('plan').value === plan.id) {
        //         this.activityForm.get('plan').reset();
        //     }
        //     this.globalRegistry.systemData.plans = this.globalRegistry.systemData.plans.filter(obj => obj.id !== plan.id);
        //     this.toastr.success('Plan deleted successfully')
        // }, (error) => {
        //     this.toastr.error(error.error.results.error) 
        // })
    }

    scrollPlans() {}

    addPlanModal() {
        this.plan = null;
        this.planId = 0;
        this.addEditPlanModal.showModal();
    }

    filterPlan(e) {}

    editPlan(plan) {
        console.log(plan);
        this.plan = plan;
        this.planId = plan.id;
        this.addEditPlanModal.showModal();
    }
    
    ngOnInit() {
        this.widgetsService.setSize(7);
        this.getSchedulers();
        this.mapService.showMap();
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });
        console.log(this.attachableEquipments);
        const res = Array.from(
            result
                .reduce((lots, lot) => {
                    lots.set(
                        lot.farm,
                        (lots.get(lot.farm) || []).concat(lot)
                    );
                    return lots;
                }, new Map())
                .entries(),
            ([farm, data]) => ({ farm, data })
        );
        this.lotGroup = res;
        
        this.subscriptions.push(
            this.navService.emitSubmit.subscribe((value: boolean) => {
                if (value) {
                    this.submitForm();
                }
            })
        );
        this.subscription.add(
            this.navService.emitCancel.subscribe((value: boolean) => {
                if (value) {
                    this.clearLocalVals();
                    if(this.route.snapshot.queryParams.from == 'crop' && !this.route.snapshot.queryParams.cropId) {
                        this.router.navigate(['/pages/crops/add'])
                        } else if(this.route.snapshot.queryParams.from == 'crop' && this.route.snapshot.queryParams.cropId) {
                          this.router.navigate(['/pages/crops/edit/'+this.route.snapshot.queryParams.cropId])
                        } else {
                            this.router.navigate(['/pages/activities']);
                        }
        
                    if(this.localDocId) {
                        this.subscription.add(this.stockService.deleteDoc(this.localDocId).subscribe(data => {
                            console.log(data);
                        }))
                    }
                    if(this.localPDocId) {
                        this.subscription.add(this.stockService.deleteDoc(this.localPDocId).subscribe(data => {
                            console.log(data);
                        }))
                    }
                    this.navService.emitCancel.next(false);
                }
            })
        );

        this.subscriptions.push(
            this.navService.emitDelete.subscribe((value: boolean) => {
                value && this.deleteCurrentActivity();
            })
        );
        
        this.persons = this.globalRegistryService.systemData.persons;
        if(this.route.snapshot.queryParams.doc) {
            this.localDocId = this.route.snapshot.queryParams.doc;
            this.getDocDetails(this.localDocId);
        }
        this.farmForm = new UntypedFormGroup({
            farm: new UntypedFormControl(null, Validators.required)
          });

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 3000,
            allowSearchFilter: true
          };

          if(this.route.snapshot.queryParams.lot) {
            this.localLots.push(+this.route.snapshot.queryParams.lot);
            this.changeLotFormat(this.localLots);
        }

          this.activityService.getActivityLots.subscribe((data) => {
            if(data) {
                this.localLots.push(data);
                this.changeLotFormat(this.localLots);
            }
          })
          
          this.companyService.getCompany().subscribe(data => {
            this.availableVAT = Object.values(data.model.vatRates);
          })

        const currentUrl = this.router.url;
        const containsAdd = currentUrl.includes('/add');
        if (containsAdd) {
          this.activityService.createEmptyActivity({}).subscribe(data => {
            console.log(data.body.results);
            this.activity = data.model;
            this.initForm();
          })
        }
    }

    addRow() {

        console.log(this.itemForm.value);
        return
      
        if(+this.itemForm.get('vat').value == 0 || this.itemForm.get('vat').value == null) {
          this.toastr.error(this.translateService.instant('stock.error.inputValidVATError'));
          return
        }
       
        if(this.itemForm.get('product').value == '' || this.itemForm.get('product').value == null) {
          return
        }
      
          const totalPrice = +(this.itemForm.get('quantity').value * this.itemForm.get('unitPrice').value);
          const vat = totalPrice +this.itemForm.get('vat').value / 100
          const obj = {
            // id: this.bill.id,
            resource: this.itemForm.get('product').value,
            quantity: +this.itemForm.get('quantity').value,
            unitPrice: +this.itemForm.get('unitPrice').value,
            vatPercentage: +this.itemForm.get('vat').value,
            totalPrice,
            vat,
            grandTotal: +(+totalPrice + +vat),
            info: this.itemForm.get('info').value,
          }
          console.log(obj);
          const docItem =  {
            // document: this.bill.id, 
            resource_entry: {
              id: obj.resource,
              unit: this.stockUnit,
              product: this.entryProductId
            }, 
            quantity: obj.quantity,
            cost: +(this.itemForm.get('unitPrice').value),
            vat: +this.itemForm.get('vat').value,
            cost_orig: +(this.itemForm.get('unitPrice').value),
            type: 0,
            info: obj.info
          }
          this.subscription.add(this.stockService.createDocumentItem(0, docItem, 0).subscribe(data => {
            console.log(data);
            // this.resDocItemIds.push(data.body.results.id);
            // this.getDocItems(this.bill.id);
            this.addEditItemModal.hideModal();
           
            this.stockUnit = null;
            this.entryProductId = null;
            this.itemForm.reset();
            this.stockItems = [];
            this.itemForm.get('product').setValue(null);
            this.entries = [];
            this.itemForm.clearValidators();
            this.initItemForm();
          }, (error) => {
            this.toastr.error(error.error.results.error);
          }))
        
      }

    public initActivityTypeForm() {
        this.activityTypeForm = this.formBuilder.group({
            name: [this.activityType ? this.activityType.name : '', Validators.compose([
                Validators.required,
            ])],
            name_t: [this.activityType ? this.activityType.nameT : '', Validators.compose([
                Validators.required,
            ])],
            color: [this.activityType ? this.activityType.color : ''],
            entity_form: [this.activityType ? this.activityType.entityForm : null],
        });
    }

    changeLotFormat(lotIds) {
        const lots = [];
      
        lotIds.forEach(element => {
          if (typeof element === 'number') {
            // If element is a number, find the corresponding lot by id
            const lot = this.lots.find(l => l.id === element);
            if (lot) {
              lots.push(lot);
            }
          } else if (Array.isArray(element)) {
            // If element is an array, use the array elements as lot ids
            element.forEach(id => {
              const lot = this.lots.find(l => l.id === id);
              if (lot) {
                lots.push(lot);
              }
            });
          }
        });
      
        const uniqueLots = lots.filter((item, pos) => lots.indexOf(item) == pos);
        console.log(uniqueLots);
        this.selectedItems = uniqueLots;
        return uniqueLots;
      }      

    get f() {
        return this.activityForm.controls;
      }

    public minValueChanged(ev) {
        this.minDate = ev.target.value;
        localStorage.setItem('started', ev.target.value);
        let date = ev.target.value;
        const parsedDate = moment(date, 'DD/MM/YYYY, HH:mm');
        const outputDateString = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
        this.activityForm.get('started').setValue(outputDateString);
        console.log(this.activityForm.value)
    }

    public maxValueChanged(ev) {
        this.maxDate = ev.target.value;
        localStorage.setItem('ended', ev.target.value);
        let date = ev.target.value
        const parsedDate = moment(date, 'DD/MM/YYYY, HH:mm');
        const outputDateString = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
        this.activityForm.get('ended').setValue(outputDateString);
    }

    public verifyFarm() {
        this.getActivityFarm(this.selectedItems);
    }

    public setActivityDocFarm() {
        this.activityDocFarm = this.farmForm.get('farm').value;
        this.getResourceInfo();
    }

    public getDocDetails(id) {
        this.subscription.add(this.stockService.getDoc(id).subscribe(data => {
            this.resourcesDoc = data.body.results;
            if(data) {
                this.getDocItems(data.body.results.id);
            }
        }))
    }

    getDocItems(id): void {
        const p0 = {
          document: id,
          type: 0
        };
      
        const p1 = {
          document: id,
          type: 1
        };

        if(!id) {
            return;
        }
      
        this.subscription.add(
          this.stockService.getDocument(p0).subscribe((data: any) => {
            if (data) {
              this.documentFarm = data.body.results[0]?.document.farm;
              console.log(data.body.results);
      
              const r_items = [];
              data.body.results.forEach(element => {
                const obj = {
                  id: element.resource_entry.id,
                  product: element.resource_entry.product,
                  quantity: element.quantity,
                };
                r_items.push(obj);
              });
              this.r_items = r_items;
              console.log(r_items);
            }
          })
        );
      
        this.subscription.add(
          this.stockService.getDocument(p1).subscribe((data: any) => {
            if (data) {
              this.documentFarm = data.body.results[0]?.document.farm;
              console.log(data.body.results);
      
              const p_items = [];
              data.body.results.forEach(element => {
                const obj = {
                  id: element.resource_entry.id,
                  product: element.resource_entry.product,
                  quantity: element.quantity,
                };
                p_items.push(obj);
              });
              this.p_items = p_items;
              console.log(p_items);
            }
          })
        );
      }      

  public showActivityTypeModal(newCheck?: boolean): void {
    if(newCheck) {
        this.activityType = null
        this.activityTypeId = null;
        // this.activityTypeForm.reset();
        this.initActivityTypeForm();
    }
    this.addActivityTypeModal.showModal();
  }

    public showActivityGroupModal(): void {
        this.addActivityGroupModal.showModal();
    }
    
    getTranslation(translation) {
        const t =this.globalRegistry.systemData.translations.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0]) {
                if(t[0][this.langString] === null || t[0][this.langString] === '') {
                  return translation
                } else {
                  return t[0][this.langString];
                }
              } else {
                return
            }
        } else {
            return translation
          }
      }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach((element) => {
            if (element.id == farmid) {
                farm = element;
            }
        });
        return farm.name;
    }

    public deleteCurrentActivity() {
        console.log(this.activity);
        this.deleteActivity.emit(this.activity.id);
        setTimeout(() => {
            this.router.navigate(['/pages/activities']);
        }, 1000);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscription.unsubscribe();
        this.objectId = null;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'activity') && this.activity) {
            this.initForm();
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'activityTypes') && this.activityTypes && this.activity) {
            this.activityTypes.forEach(type => {
                if(type.id === this.activity.type) {
                    console.log(type);
                    this.activityType = type;
                }
            });
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'baselineActivityId') && this.baselineActivityId) {
            this.getActivity(this.baselineActivityId);
        }
        console.log(changes)
        if(Object.prototype.hasOwnProperty.call(changes, 'isBaselineActivity') && this.isBaselineActivity) {
            if(Object.prototype.hasOwnProperty.call(changes, 'baselineActivityId') && this.baselineActivityId) {
                this.getActivity(this.baselineActivityId);
            }
        }
        if ( 
            Object.prototype.hasOwnProperty.call(changes, 'isSubmitDisabled') &&
            this.isSubmitDisabled
        ) {
            this.navService.submitFarm.next(false);
        } else {
            this.navService.submitFarm.next(true);
        }
    }

    public verifyInitialPlan() {
        this.baselineActivity.emit(this.activity.baseline);
        this.triggerBaselineActivity.emit(this.activity);
        console.log(this.activity);
    }

    public getActivity(activityId) {
        this.activityService.getActivity(activityId).subscribe(data => {
            this.activity = data.model;
            this.initForm();
        }, (error) => {
            this.toastr.error(error.error.results.error);
            this.closeModal.emit();
        })
    }

    checkLocalDate(control) {
        if(localStorage.getItem(control)!= null) {
            return moment(localStorage.getItem(control)).format('yyyy-MM-DD hh:mm:ss')
          } else return moment(new Date()).format('yyyy-MM-DD hh:mm:ss')
    }

    checkLocalVal(control) {
        if(localStorage.getItem(control)!= null) {
          return +localStorage.getItem(control)
        }
      }

      checkLocalInput(control) {
        console.log(localStorage.getItem(control));
        if(localStorage.getItem(control)!= null) {
            return localStorage.getItem(control)
          } else {
            return
          }
      }

    public initForm() {
        const formBody: {} = {
            activityType: [
                this.activity ? this.activity.type : this.checkLocalVal('activityType'),
                Validators.compose([Validators.required]),
            ],
            // group: [
            //     this.activity ? this.activity.group : this.checkLocalVal('group')
            // ],
            // lots: [this.activity ? this.activity.lots : this.checkLocalVal('lots')],
            baseline: [this.activity ? this.activity.baseline : null],
            assignedTo: [this.activity ? this.activity.assignedTo : this.checkLocalVal('assignedTo')],
            started: [
                this.activity ? moment(this.activity.started).format('') : this.checkLocalDate('started'),
                Validators.compose([Validators.required]),
            ],
            ended: [
                this.activity ? moment(this.activity.ended).format('') : null,
            ],
            info: [this.activity ? this.activity.info : this.checkLocalInput('info')],
            images: [this.activity ? this.activity.images : []],
            name: [this.activity ? this.activity.name : this.checkLocalInput('name'), Validators.compose([Validators.required])],
            name_t: [
                this.activity ? this.activity.nameT : ''
            ],
            // phaseId: [this.activity ? this.activity.phaseId : this.checkLocalInput('phaseId')],
            usedEquipment: [
                this.usedEquipment ? this.usedEquipment.id : null,
            ],
            transactions: [this.transactions ? this.transactions : []],
            plan: [this.activity ? this.activity.plan : null],
            parent: [this.activity ? this.activity.parent : null],
            schedule: [this.activity ? this.activity.schedule : null],
            attachedEquipment1: [
                this.attachableEquipments ? this.attachableEquipments[0] ? this.attachableEquipments[0].id : null : null
            ],
            attachedEquipment2: [
                this.attachableEquipments ? this.attachableEquipments[1] ? this.attachableEquipments[1].id : null : null
            ],
            timestampTime: [
                this.activity
                    ? moment(this.activity.started).format('LT')
                    : null,
            ],
            untilTime: [
                this.activity ? moment(this.activity.ended).format('LT') : null,
            ],
            selectedItems: [
                this.selectedItems ? this.selectedItems : this.checkLocalVal('lots')
            ],
            resourcesDoc: [
                this.activity ? this.activity.resourcesDoc : null
            ]
        };

        if(!this.activity) {
            this.minDate = moment(new Date).format('')
        }

        if(this.activity) {
            console.log(this.activity);
            // this.localLots = this.activity.lots;
            this.activityId = this.activity.id;
            this.assignedTo = Object.assign({
                firstName: this.activity.assignedTo,
            });
            this.activityImages = this.activity.images;
            this.globalRegistry.systemData.translations.forEach(translation => {
                if(translation.id === this.activity.nameT) {
                    this.nameT = translation;
                    console.log(this.nameT);
                }
            });
            formBody['id'] = this.activity.id;
            this.activityEquipments = this.activity.attachedEquipments;
            console.log(this.activityEquipments);
            this.usedEquipment = this.activityEquipments.filter(eq => {
                return eq.attachable == 0
            });
            this.activityEquipments.filter(eq => {
                if(eq.attachable == 1) {
                    this.attachableEquipments.push(eq);
                }
            });
            if(this.activity.attachedEquipments.length > 0) {
            this.usedEquipment = this.usedEquipment[0].id
            console.log(this.attachableEquipments);
            this.attachedEquipment1 = this.attachableEquipments ? this.attachableEquipments[0] ? this.attachableEquipments[0].id : null : null;
            this.attachedEquipment2 = this.attachableEquipments ? this.attachableEquipments[1] ? this.attachableEquipments[1].id : null : null;
            }
            if(this.activity.baseline) {
                this.baselineActivityId = this.activity.baseline;
            }
            if(this.activity.started) {
                this.minDate = this.activity.started;
                console.log(this.minDate);
            }
            if(this.activity.ended) {
                this.maxDate = this.activity.ended;
                console.log(this.maxDate);
            }
            this.activityTypes.forEach(type => {
                if(type.id === this.activity.type) {
                    console.log(type);
                    this.activityType = type;
                    this.objectId = this.activityType.entityForm;
                    this.getFormTypeById(this.activityType.entityForm);
                }
            });
            // this.selectedItems = this.changeLotFormat(this.activity.lots);
        } else {
            this.usedEquipment = this.checkLocalVal('usedEquipment');
            this.attachedEquipment1 = this.checkLocalVal('attachedEquipment1');
            this.attachedEquipment2 = this.checkLocalVal('attachedEquipment2');
        }
        this.activityForm = this.formBuilder.group(formBody);
        this.initialActivityForm = this.activityForm.value;
        // in preview mode we should not be able to edit or submit the form
        this.isPreviewMode
            ? this.activityForm.disable()
            : this.activityForm.enable();

        this.activityForm.valueChanges.subscribe(() => {
            if (this.activityForm.invalid) {
                this.navService.invalidForm.next(false);
            } else {
                this.navService.invalidForm.next(true);
            }
        });
    }

    addImage(equipment: EquipmentModel) {
        if (equipment) {
            console.log(equipment);
            const exists = this.activityEquipments.some(item => item.id === equipment.id);
            if (!exists) {
                let newEquipment = {
                    attachable: equipment.attachable,
                    id: equipment.id,
                    product: equipment.product,
                    registration_plate: equipment.registrationPlate
                };
                
                this.activityEquipments = [...this.activityEquipments, newEquipment];
                console.log(this.activityEquipments);
            } else {
                this.toastr.error('Equipment already added');
            }
        }
    }
    
    deleteImage(equipmentId: number) {
        console.log(equipmentId)
        this.activityEquipments = this.activityEquipments.filter(item => item.id !== equipmentId);
    }

    calcTime(time) {
        return moment.duration(time).asMinutes();
    }

    updateItems(lots) {
        this.activityForm.get('lots').setValue(lots);
        this.selectedItems = lots;
    }

    

  editScheduler(scheduler) {
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.addEditSchedulerModal.showModal();
  }

  deleteScheduler(schedulerId: number) {
    this.sensorService.deleteScheduler(schedulerId).subscribe(
        () => {
            this.schedulers = this.schedulers.filter(scheduler => scheduler.id !== schedulerId);
            this.toastr.success('Scheduler deleted successfully')
        },
        error => {
          this.toastr.error(error.error.results.error);
        }
    );
  }

  setScheduler(scheduler) {
    console.log(scheduler);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.activityForm.get('schedule').setValue(scheduler.id);
  }

  setParent(activity) {
    console.log(activity);
    this.activityForm.get('parent').setValue(activity.id);
  }

  setFormType(formType) {
    this.activityTypeForm.get('entity_form').setValue(formType.id);
    if(this.activityType.entityForm === formType.id || this.activityType.entityForm == null) {
        this.getFormTypeById(formType.id);
    } else {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('forms.changeFormType'),
            this.translateService.instant('forms.changeFormTypeMessage'));
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    if(formType.id) {
                        this.objectId = formType.id;
                        this.activityTypeForm.get('entity_form').setValue(formType.id);
                        this.getFormTypeById(formType.id);
                    }
                }
            }
        );
    }
  }

  verifyFormType(activityType: ActivityTypeModel) {    
    if(activityType.entityForm) {
        this.confirmModalService.openConfirmModal(
        this.translateService.instant('forms.changeFormType'),
        this.translateService.instant('forms.changeFormTypeMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                if(activityType.entityForm) {
                    this.objectId = activityType.entityForm;
                    this.activityForm.get('type').setValue(activityType.id);
                    this.getFormTypeById(activityType.entityForm)
                }
            }
        });
    } else {
        this.entityInfo = null;
        this.objectId = null;
    }
  }

  createNewScheduler() {
    this.scheduler = null; 
    this.schedulerId = 0; 
    this.addEditSchedulerModal.showModal();
  }

  addedScheduler(scheduler) {
    this.activityForm.get("schedule").setValue(scheduler.id);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.schedulers.unshift(scheduler);
    this.closeCombo = true;
  }

  updatedScheduler(scheduler) {
    this.activityForm.get("schedule").setValue(scheduler.id);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    const indexToUpdate = this.schedulers.findIndex(s => s.id === scheduler.id);
    if (indexToUpdate !== -1) {
      this.schedulers[indexToUpdate] = scheduler;
    }
    this.closeCombo = true;
  }

  public updateActivityWithDoc() {
    const processFormData = () => {
        console.log(this.activityForm.value);

        if (this.activityForm.get('ended').value == 'Invalid date') {
            this.activityForm.get('ended').patchValue(null);
            console.log(this.activityForm.get('ended').value);
        }

        if (this.activityForm.invalid) {
            this.activityForm.markAllAsTouched();
            this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
            return null;
        }

        const formatDateTime = (value) => 
            typeof value === 'string' ? (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null) : 
            value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;

        const started = formatDateTime(this.activityForm.value['started']);
        const ended = formatDateTime(this.activityForm.value['ended']);

        const attachedEquipments = this.activityEquipments.map(equipment => equipment.id);

        const lotIds = this.selectedItems.map(element => element.id);

        const activity: ActivityModel = {
            name: this.activityForm.value['name'],
            nameT: this.activityForm.value['name_t'],
            type: this.activityForm.value['activityType'],
            started: started,
            ended: ended,
            info: this.activityForm.value['info'],
            assignedTo: this.activityForm.value['assignedTo'],
            attachedEquipments: attachedEquipments,
            plan: this.activityForm.value['plan'],
            parent: this.activityForm.value['parent'],
            schedule: this.activityForm.value['schedule'],
            images: this.activityForm.value['images'],
            resourcesDoc: this.activityForm.value['resourcesDoc']
        };

        return activity;
    };

    const handleActivity = (activity) => {
        if (!activity) return;

        console.log(activity);
        if (this.activity) {
            activity.id = this.activity.id;
            this.updateLocalActivity(activity);
        }
    };

    const activity = processFormData();
    handleActivity(activity);

    if (this.isBaselineActivity) {
        this.clearLocalVals();
    }
}

public submitForm() {
    const processFormData = () => {
        console.log(this.activityForm.value);

        if (this.activityForm.get('ended').value == 'Invalid date') {
            this.activityForm.get('ended').patchValue(null);
            console.log(this.activityForm.get('ended').value);
        }

        if (this.activityForm.invalid) {
            this.activityForm.markAllAsTouched();
            this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
            return null;
        }

        const formatDateTime = (value) => 
            typeof value === 'string' ? (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null) : 
            value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;

        const started = formatDateTime(this.activityForm.value['started']);
        const ended = formatDateTime(this.activityForm.value['ended']);

        const attachedEquipments = this.activityEquipments.map(equipment => equipment.id);

        const lotIds = this.selectedItems.map(element => element.id);

        const activity: ActivityModel = {
            name: this.activityForm.value['name'],
            nameT: this.activityForm.value['name_t'],
            type: this.activityForm.value['activityType'],
            started: started,
            ended: ended,
            info: this.activityForm.value['info'],
            assignedTo: this.activityForm.value['assignedTo'],
            attachedEquipments: attachedEquipments,
            plan: this.activityForm.value['plan'],
            parent: this.activityForm.value['parent'],
            schedule: this.activityForm.value['schedule'],
            images: this.activityForm.value['images'],
            resourcesDoc: this.activityForm.value['resourcesDoc']
        };

        return activity;
    };

    const handleActivitySubmission = (activity) => {
        if (!activity) return;

        console.log(activity);

        if (this.activity) {
            activity.id = this.activity.id;
            if (this.isBaselineActivity) {
                this.updateBaselineActivity.emit(activity);
            } else {
                this.updateActivity.emit(activity);
            }
        } else {
            if (this.isBaselineActivity) {
                this.submitBaselineActivity.emit(activity);
            } else {
                this.submitActivity.emit(activity);
            }
        }

        this.closeModal.emit();
        this.activity = null;
        this.resetForm();
    };

    const activity = processFormData();
    handleActivitySubmission(activity);
}

private resetForm() {
    this.activityForm.reset();
    this.activityForm.clearValidators();
    this.activityForm.get('activityType').markAsUntouched();
    this.nameT = null;
    this.translation = null;
    this.activityImages = [];
    this.initForm();
}


    closePopup(event: PersonModel) {
        this.assignedTo = event;
        this.isPerson = false;
        this.activityForm.controls['assignedTo'].setValue(
            event.id ? event.id : null
        );
    }

    public resetActivityForm(): void {
        this.activityForm.setValue(this.initialActivityForm);
        this.activityForm.markAsPristine();
    }

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }

      addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
        console.log(inputElement);
        if(popup === this.addEditNameTranslationsModal) {
            console.log('activity modal')
        } else {
            console.log('type modal')
        }
        if(translationTarget.id === this.activity.id && this.isBaselineActivity) {
            return
        }
        this.label = label;
        const name = this[inputElement].nativeElement.value;
        const type: any = this.convertKeysToCamelCase(translationTarget);
        console.log(name);
        console.log(type);
        if (name === null || name === '' || type.nameT === null) {
            if(inputElement === 'nameInput') {
                this.translation = null;
            } else {
                this.nameT = null;
            }
            popup.showModal();
        } else {
            let found;
            if(inputElement === 'nameInput') {
                found = this.globalRegistry.systemData.translations.find(translation => translation.id === type.nameT);
            } else {
                found = this.globalRegistry.systemData.translations.find(translation => translation.id === type.nameT);
            }
            if(!found) {
                if(inputElement === 'nameInput') {
                    this.getTranslationByKey(type.nameT, popup);
                } else {
                    this.getTranslationByKey(type.nameT, popup);
                }
            } else {
                for (const translation of this.globalRegistry.systemData.translations) {
                    if (type && translation.id === type.nameT) {
                        if(inputElement === 'nameInput') {
                            this.translation = translation;
                            console.log(this.translation);
                        } else {
                            this.nameT = translation;
                            console.log(this.nameT);
                        }
                        popup.showModal();
                        return;
                    }
                }
                
                if (Object.keys(type).length === 0 && name) {
                    const newTranslation = this[form].controls['name_t'].value;
                    for (const translation of this.globalRegistry.systemData.translations) {
                        if (translation.id === newTranslation) {
                            if(inputElement === 'nameInput') {
                                this.translation = translation;
                                console.log(this.translation);
                            } else {
                                this.nameT = translation;
                                console.log(this.nameT);
                            }
                            popup.showModal();
                            return;
                        }
                    }
                }
            }
        }
      }

      getTranslationByKey(key, popup) {
        this.subscriptions.push(
          this.typesService.getTranslationByKey(key).subscribe(data => {
            if(data) {
              if(popup === this.addEditTranslationsModal) {
                this.translation = data.body.results;
                this.addEditTranslationsModal.showModal();
              } else {
                this.nameT = data.body.results;
                this.addEditNameTranslationsModal.showModal();
              }
              const index = this.globalRegistry.systemData.translations.findIndex(item => item.id === data.body.results.id);
              if (index !== -1) {
                this.globalRegistry.systemData.translations[index] = data.body.results;
                this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
                this.translatedNames = this.globalRegistry.systemData.translations;
                this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
              } else {
                this.globalRegistry.systemData.translations.push(data.body.results);
                this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
                this.translatedNames = this.globalRegistry.systemData.translations;
                this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
              }
            }
          })
        )
      }

      public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
        arrayOfObjects.forEach(obj => {
            objectMap.set(obj.id, obj);
            if(obj.children && obj.children.length) {
               this.createObjectMap(obj.children, objectMap);
            }
        });
        return objectMap;
      }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.activityTypeForm.controls['name_t'].setValue(translation.id);
        this.activityTypeForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    onAddUpdateNameTranslation(translation) {
        console.log(translation);
        this.nameT = translation;
        this.activityForm.controls['name_t'].setValue(translation.id);
        this.activityForm.controls['name'].setValue(translation[this.langString]);
        this.addEditNameTranslationsModal.hideModal();
    }

    public getProducts() {
        const url = this.stockService.getUrl('products/');
        this.subscriptions.push(this.stockService.getProducts({ limit: this.limit, group: 'EQUIPMENT' }).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit+'&group=EQUIPMENT');
          console.log(data);
          this.products = data.body.results;
          this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
        }))
      }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

      scrollPersons() {
        const url = this.companyService.getUrl('');
        console.log('person scrolled');
        if(this.getMorePersons) {
          this.personToken &&
            this.subscription.add(this.companyService.getPersons(this.personToken).subscribe((data) => {
              this.cacheService.delete(url+'limit='+this.personToken.limit+'&offset='+this.personToken.offset+'&search='+this.personToken.search+'&own=1');
              console.log(data);
              if(this.persons) {
                this.persons = [...this.persons, ...data.body.results];
              } else {
                this.persons = data.body.results;
              }
              if(data.body.next == null) {
                this.getMorePersons = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.personToken.offset != params['offset']) {
                this.personToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
      }
      
      onPersonScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          this.scrollPersons();
        }
      }

      createEquipment() {
        this.router.navigate(['pages/equipments/add']);
      }

    initItemForm() {
        this.itemForm = new UntypedFormGroup({
          product: new UntypedFormControl(null, Validators.required),
          offer: new UntypedFormControl(null),
          info: new UntypedFormControl(null),
          quantity: new UntypedFormControl(null, Validators.required),
          unitPrice: new UntypedFormControl(null, Validators.required),
          vat: new UntypedFormControl(null, Validators.required)
      });
    }

    getResourceInfo() {
        console.log(this.activity);
        console.log(this.isBaselineActivity);
        this.docType = this.isBaselineActivity ? 'estimation_note' : 'production_note';
        this.docId = this.activity.resourcesDoc;
        this.isNewDoc = this.docId ? false : true;
        this.isModalView = true;
        this.addEditResourceModal.showModal();
    }

    getStockItems() {
        this.subscription.add(this.stockService.getStockItems().subscribe((data: ResponseModel<StockItemModel[]>) => {
          this.stockItems = data.body.results;
          console.log(data.body.results);
        }))
      }
    
      getFilteredStockItems(productId, farmId) {
        this.stockItems = [];
        const obj = {
          product: productId,
          farm: farmId
        }
        this.subscription.add(this.stockService.getStockResources(obj).subscribe((data: ResponseModel<StockResourceModel[]>) => {
          this.entries = data.body.results;
          console.log(data.body.results);
        }))
      }
    
      getStockResources(stockId) {
        console.log(stockId)
        this.subscription.add(this.stockService.getStockResourceEntry(stockId).subscribe((data: ResponseModel<StockResourceModel[]>) => {
          this.entries = data.body.results;
          console.log(this.entries);
          if(this.entries.length > 0) {
            this.itemForm.controls['product'].setValue(this.entries[0].id);
            // this.productionNoteItemForm.controls['entry'].setValue(this.entries[0].id);
            this.itemForm.controls['unitPrice'].setValue(this.entries[0].cost);
            this.itemForm.controls['vat'].setValue(this.entries[0].vat);
            this.itemForm.controls['quantity'].setValue(this.entries[0].remains_quantity);
            this.stockUnit = this.entries[0].unit;
            this.max = this.entries[0].quantity;
            this.entryProductId = this.entries[0].product.id;
            this.setEntry(this.entries[0]);
          } else {
            this.itemForm.reset();
            this.stockItems = [];
            this.itemForm.get('product').setValue(null);
            this.entries = [];
            this.itemForm.clearValidators();
            this.initItemForm();
            this.stockUnit = null;
            this.max = null;
          }
        }));
      }

      setEntry(event) {
        console.log(event);
        if(event.value != null) {
          this.max = event.quantity;
        }
        if(this.entries.length) {
        this.selectedEntry = this.entries.filter(e => {
          return e.product.id == event.product.id;
        });
        console.log(this.selectedEntry);
        this.itemForm.controls['unitPrice'].setValue(this.selectedEntry[0].cost.toFixed(this.globalRegistry.systemData.company.digits));
        this.itemForm.controls['vat'].setValue(this.selectedEntry[0].vat);
        this.itemForm.controls['quantity'].setValue(this.selectedEntry[0].remains_quantity);
        this.stockUnit = this.selectedEntry[0].unit;
        this.max = this.selectedEntry[0].quantity;
        this.entryProductId = this.selectedEntry[0].product.id
        // console.log(event.value.quantity);
        }
      }

    public addPlan(plan) {
        this.globalRegistry.systemData.plans.unshift(plan);
        this.updatePlanActivity(plan.id);
        this.addEditPlanModal.hideModal();
        this.closeCombo = true;
    }

    public updatePlan(plan) {
        this.activityForm.get("plan").setValue(plan.id);
        this.plan = plan;
        const indexToUpdate = this.globalRegistry.systemData.plans.findIndex(s => s.id === plan.id);
        if (indexToUpdate !== -1) {
            this.globalRegistry.systemData.plans[indexToUpdate] = plan;
        } else {
            console.warn(`Plan with ID ${plan.id} not found in the global registry`);
        }
        this.closeCombo = true;
        this.updatePlanActivity(plan.id);
        this.addEditPlanModal.hideModal();
    }    

    updatePlanActivity(plan) {
        this.activityForm.get('plan').setValue(plan);
    }

      addActivityImage(event) {
        const imageId = event.value.id;
        this.activityImages = [...this.activityImages, imageId];
        this.activityForm.get('images').setValue(this.activityImages);
        console.log(this.activityImages);
        console.log(this.activityForm.value);
    }    

    deleteActivityImage(imgId) {
        this.activityImages = this.activityImages.filter((item) => item !== imgId);
        console.log(this.activityImages);
        this.activityForm.get('images').setValue(this.activityImages);
        console.log(this.activityForm.value);
    }

    docUpdate(doc) {
        console.log(doc);
        this.activity.resourcesDoc = doc;
        this.activityForm.get('resourcesDoc').setValue(doc);
        if(doc) {
            this.addEditResourceModal.hideModal();
            this.updateActivityWithDoc();
        }
        this.isModalView = null;
    }

    handleClose() {
        this.docId = null;
        this.isModalView = null;
        this.docType = null;
        this.isNewDoc = null;
    }

    getDisplay(): string {
        let display = '';
        if(this.isBaselineActivity) {
            display = 'none'
        } else display = 'block'
        return display;
    }

    deleteDoc(ev) {
        console.log(ev);
        this.activity.resourcesDoc = null;
        this.activityForm.get('resourcesDoc').setValue(null);
        this.isModalView = null;
    }

    public updateLocalActivity(activity: ActivityModel): void {
        this.activityService.updateActivity(activity.id, activity).subscribe(data => {
          if(data) {
              this.toastr.success(
                  this.translate.instant("activity.activityUpdatedSuccessfully")
                );
            this.activity = data.model;
            this.initForm();
          }
        }, (err) => {
          this.toastr.error(err.error.results.error)
        })
    }

}
