<div class="add_edit_plan">
    <form class ="prod_search" [formGroup]="planForm">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput', plan, addEditPlanTranslationPopup, 'planForm')">
                    <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput>
                    <mat-error *ngIf="planForm.controls['name_t'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <fap-add-edit-delete-select 
                    [inputName]="'Plan type'"
                    class="w-100"
                    [formControl]="planForm.controls['type']"
                    name="type"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'name_t'"
                    [inputSelectValues]="planTypes"
                    [inputConfirmDeleteText]="'auth.company.deletePlanTypeMessage' | translate"
                    (outputOnCreate)="showPlanTypeModal(); closeCombo = false;"
                    (outputOnEdit)="planTypeEdit($event); closeCombo = false;"
                    (outputOnDelete)="planTypeDelete($event);"
                    (selectionChange)="planForm.get('type').setValue($event)"
                    [closeCombo]="closeCombo"
                    >
                </fap-add-edit-delete-select>
            </div>
            <div class="col-12 mb-2">
                <chips 
                    [items]="globalRegistry?.systemData?.lots" 
                    [selectedItems]="plan ? plan.lots : []"
                    [label]="'activities.lots' | translate"
                    (updatedItems)="updateItems($event)"
                    [addEnabled]="false"
                >
                </chips>
            </div>
            <div class="col-4 pr-0">
                <mat-form-field class="w-100" (click)="start.open()">
                    <mat-label>
                        {{ 'Start' }}</mat-label>
                    <input matInput readonly formControlName="start" [matDatepicker]="start">
                    <mat-datepicker-toggle matSuffix [for]="start">
                    </mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                    <mat-error *ngIf="ifControlHasError('start','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="w-100" (click)="end.open()">
                    <mat-label>
                        {{ 'End' }}</mat-label>
                    <input matInput readonly formControlName="end" [matDatepicker]="end">
                    <mat-datepicker-toggle matSuffix [for]="end">
                    </mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                    <mat-error *ngIf="ifControlHasError('end','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-4 pl-0">
                <mat-form-field class="w-100 d-flex">
                    <input formControlName="progress" matInput placeholder="{{ 'farm.crop.progress' | translate }}" style="text-align: right; padding-right: 10px; width: calc(100% - 40px);"> <span>%</span>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="card mt-2" style="margin-bottom: 0" *ngIf="plan">
        <fap-section-container [isTable]="true" [title]="'stock.consumed' | translate">
            <items-table [items]="resources?.consumed?.items" [showCosts]="true" [cost]="resources?.consumed?.cost"></items-table>
        </fap-section-container>
    </div>
    
    <div class="card mt-2" style="margin-bottom: 0" *ngIf="plan">
        <fap-section-container [isTable]="true" [title]="'stock.produced' | translate">
            <items-table [items]="resources?.produced?.items" [showCosts]="true" [cost]="resources?.produced?.cost"></items-table>
        </fap-section-container>
    </div>

    <ng-container *ngIf="planId">
        <div class="mt-2">
            <fap-attributes
                [entityRelation]="'plan'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="planId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
                >
            </fap-attributes>
        </div>
    </ng-container>

    <div class="col-12 mt-3 p-0">
        <div class="action_btns d-flex justify-content-end p-0">
            <button class="btn mr-2" (click)="submitPlan();">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</div>

<fap-modal #addEditPlanTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="plan" 
        [prefix]="'PLAN_'" 
        [translation]="translation"
        [label]="label"
        [group]="'activities'"
        (submitTranslation)="onAddUpdatePlanTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditPlanTypeModal [inputPrimaryButtonInterface]="addEditPlanTypeModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="addEditPlanTypeModalButtonSecondaryInterface" [inputTitle]="'activity.addEditPlanType' | translate">
        <form [formGroup]="planTypeForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput1', planType, addEditTranslationsModal, 'planTypeForm')">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput1/>
                    <mat-error *ngIf="planTypeForm.controls['name'].errors?.required">
                      <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <fap-add-edit-delete-select
                    inputName="{{ 'stock.formType' | translate }}"
                    class="w-100"
                    [formControl]="planTypeForm.controls['entity_form']"
                    name="formType"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="formTypes"
                    [closeCombo]="closeCombo"
                    [isEditable]="false"
                    [isDeleteable]="false"
                    [createEnabled]="false"
                    (selectionChange)="planTypeForm.get('entity_form').setValue($event)"
                    (clicked)="setFormType($event)"
                >
                </fap-add-edit-delete-select>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ "notes.color" | translate }}</mat-label>
                    <input class="w-100" matInput type="color" formControlName="color"/>
                    <mat-error *ngIf="planTypeForm.controls['color'].errors?.required">
                        <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
        <add-edit-translations 
            [obj]="convertKeysToCamelCase(this.planType)" 
            [prefix]="'TYPE_'" 
            [label]="label"
            [translation]="translation"
            [group]="'types'"
            (submitTranslation)="onAddUpdateTranslation($event)" 
        >
        </add-edit-translations>
    </fap-modal>