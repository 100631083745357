<div class="row rsp" id="parent">
    <div class="col-12 rspc">
        <div class="card">
                <form [formGroup]="activityForm" class="w-100">
                    <div class="ace">
                        <div class="form-group">
                            <div class="btn-container" *ngIf="!isBaselineActivity && activity?.id">
                                <button class="btn" (click)="verifyInitialPlan()">{{ 'activity.initialPlan' | translate }}</button>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row align-items-baseline">
                            <div class="col-12 tbg">
                                    <mat-form-field class="w-100" (click)="addEditTranslation('activities.title', 'nameInput1', activity, addEditNameTranslationsModal, 'activityForm')">
                                        <mat-label>{{ "activity.title" | translate }}</mat-label>
                                        <input
                                            matInput
                                            formControlName="name"
                                            name="name"
                                            class="p-0"
                                            style="height: 16px;"
                                            [disabled]="isBaselineActivity"
                                            readonly #nameInput1
                                        />
                                        <mat-error
                                                *ngIf="
                                                    activityForm.controls[
                                                        'name'
                                                    ].errors?.required
                                                "
                                            >
                                                <strong>{{
                                                    "auth.validation.requiredField"
                                                        | translate
                                                }}</strong>
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row align-items-baseline">
                                <div class="col-6 tbg">
                                    <fap-add-edit-delete-select 
                                        [inputName]="'activity.activityType' | translate"
                                        class="w-100"
                                        [formControl]="activityForm.controls['activityType']"
                                        name="type"
                                        [inputIsRequired]="true"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="activityTypes"
                                        [inputConfirmDeleteText]="'activities.deleteActivityTypeMessage' | translate"
                                        (outputOnCreate)="showActivityTypeModal(true); closeCombo = false;"
                                        (outputOnEdit)="activityTypeEdit($event); closeCombo = false;"
                                        (outputOnDelete)="activityTypeDelete($event);"
                                        (clicked)="verifyFormType($event)"
                                        (selectionChange)="activityForm.get('activityType').setValue($event)"
                                        [closeCombo]="closeCombo"
                                        >
                                    </fap-add-edit-delete-select>
                                </div>
                                <div class="col-6 mb-2">
                                    <fap-add-edit-delete-select
                                        inputName="{{ 'auth.company.plan' | translate }}"
                                        class="w-100"
                                        [formControl]="activityForm.controls['plan']"
                                        name="plan"
                                        [inputIsRequired]="false"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="globalRegistry?.systemData?.plans"
                                        (selectionChange)="updatePlanActivity($event)"
                                        [inputConfirmDeleteText]="'auth.company.planDeleteMessage' | translate"
                                        (outputOnDelete)="onDeletePlan($event)"
                                        (outputOnScroll)="scrollPlans()"
                                        (outputOnCreate)="addPlanModal(); closeCombo = false;"
                                        (outputOnSearch)="filterPlan($event)"
                                        (outputOnEdit)="editPlan($event); closeCombo = false;"
                                        [closeCombo]="closeCombo"
                                    >
                                    </fap-add-edit-delete-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row align-items-baseline">
                                <div class="col-6 mb-2">
                                    <fap-add-edit-delete-select
                                        inputName="{{ 'activity.activityLinkedTo' | translate }}"
                                        class="w-100"
                                        [formControl]="activityForm.controls['parent']"
                                        name="plan"
                                        [inputIsRequired]="false"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="activities"
                                        [inputConfirmDeleteText]="'activity.parentDeleteMessage' | translate"
                                        [closeCombo]="closeCombo"
                                        [isEditable]="false"
                                        [isDeleteable]="false"
                                        [createEnabled]="false"
                                        (clicked)="setParent($event)"
                                        (outputOnScroll)="scrolledDownActivities.emit()"
                                        (outputOnSearch)="filterActivities.emit($event)"
                                        (selectionChange)="activityForm.get('parent').setValue($event)"
                                    >
                                    </fap-add-edit-delete-select>
                                </div>
                                <div class="col-6 mb-2">
                                    <fap-add-edit-delete-select 
                                        [inputName]="'activity.schedule' | translate"
                                        style="max-width: 100%; padding: 0;"
                                        class="nhalf_width col-4"
                                        [formControl]="activityForm.controls['schedule']"
                                        name="schedule"
                                        [inputIsRequired]="false"
                                        [apiSearchEnabled]="true"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'name_t'"
                                        [inputValueFieldName]="'id'"
                                        [inputSelectValues]="schedulers"
                                        [inputConfirmDeleteText]="'widget.confirmDeleteScheduler' | translate"
                                        (outputOnDelete)="deleteScheduler($event.id)"
                                        (outputOnCreate)="createNewScheduler(); closeCombo = false;"
                                        (outputOnEdit)="editScheduler($event); closeCombo = false;"
                                        (clicked)="setScheduler($event)"
                                        (selectionChange)="activityForm.get('schedule').setValue($event)"
                                        [closeCombo]="closeCombo"
                                        >
                                    </fap-add-edit-delete-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row align-items-baseline">
                                <div class="col-6">
                                    <mat-form-field class="pos_rel w-100">
                                        <input matInput [owlDateTime]="dt1" placeholder="Started" formControlName="started" [max]="maxDate" (change)="minValueChanged($event)">
                                        <span class="datetimepicker" [owlDateTimeTrigger]="dt1"><i class="fa fa-calendar"></i></span>
                                        <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="pos_rel w-100">
                                        <input matInput [owlDateTime]="dt2" placeholder="Ended" formControlName="ended" [min]="minDate" (change)="maxValueChanged($event)">
                                        <span class="datetimepicker" [owlDateTimeTrigger]="dt2"><i class="fa fa-calendar"></i></span>
                                        <owl-date-time [pickerMode]="'dialog'" #dt2></owl-date-time>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style="margin-bottom: 1rem!important;">
                            <label class="col-form-label float p-0 " style="font-size: 0.8rem;">{{ "notes.images" | translate }}</label>
                            <fap-image-list [imageIds]="activityImages" [group]="'activities'" (deleteImage)="deleteActivityImage($event)" (addImage)="addActivityImage($event)"></fap-image-list>
                        </div>
                        <div class="form-group imgs">
                            <div class="row m-0 ri">
                                <div class="assigned_wrapper">
                                    <label class="col-form-label float p-0 " style="font-size: 0.8rem;">{{ "Assigned to" }}</label>
                                    <div class="col-person">
                                    <div class="person_box">
                                        <div class="person_name">
                                            <div class="combo_box">
                                                <div class="input-group w-100">
                                                    
                                                    <mat-form-field>
                                                        <mat-label>{{
                                                            "Assigned to"
                                                        }}</mat-label>
                                                        <mat-select
                                                            formControlName="assignedTo"
                                                            name="assignedTo"
                                                            (infiniteScroll)="scrollPersons()"
                                                            (selectionChange)="setLocalVal('assignedTo', $event)"
                                                            msInfiniteScroll
                                                        >
                                                            <mat-form-field
                                                                appearance="fill"
                                                                class="w-100"
                                                            >
                                                                <input
                                                                    matInput
                                                                    #personFilter
                                                                    placeholder="search"
                                                                />
                                                            </mat-form-field>
                                                            <div
                                                                class="scroll_wrap"
                                                                (scroll)="onPersonScroll($event)"
                                                            >
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                                <mat-option
                                                                    *ngFor="
                                                                        let person of globalRegistry?.systemData?.persons
                                                                            | filter
                                                                                : personFilter.value
                                                                    "
                                                                    [value]="
                                                                        person?.id
                                                                    "
                                                                    >{{
                                                                        person?.firstName
                                                                    }}
                                                                    {{
                                                                        person?.lastName
                                                                    }}
                                                                    {{ person?.first_name }} {{ person?.last_name }}
                                                                    </mat-option
                                                                >
                                                            </div>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="person_image user_im img_place_bg">
                                            <ng-container
                                                *ngFor="
                                                    let el of globalRegistry?.systemData?.persons
                                                        | filter: personFilter.value
                                                "
                                                >
                                                <span><img
                                                    *ngIf="
                                                        el?.id ==
                                                            activityForm.controls
                                                                .assignedTo.value &&
                                                        el?.picture != null
                                                    "
                                                    [src]="mediaUrl+ el?.picture"
                                                    alt="Profile"
                                                />
                                                <img
                                                    *ngIf="
                                                        el?.id ==
                                                            activityForm.controls
                                                                .assignedTo.value &&
                                                        el?.picture == null
                                                    "
                                                    src="../../../../../../assets/images/people_placeholder.png"
                                                    alt="Profile"
                                                /></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="images_list_scrollable">
                                    <label class="col-form-label float p-0 " style="font-size: 0.8rem;">{{ "Attached Equipments" }}</label>
                                    <ng-container *ngIf="equipments && equipments.length === 0">
                                        <span style="display: inline-block; width: calc(100% - 110px);"> No equipments found. <span style="border-bottom: 1px solid red; cursor: pointer;" (click)="createEquipment()">Click here</span> to add a new equipment </span>
                                    </ng-container>
    
                                    <ng-container *ngIf="equipments && equipments.length !== 0">
                                        <fap-equipment-combo [equipments]="equipments" [activityEquipments]="activityEquipments" (addImage)="addImage($event.value)" (deleteImage)="deleteImage($event)">
                                        </fap-equipment-combo>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{ "activities.info" | translate }}</mat-label>
                                        <textarea
                                            class="info"
                                            style="height: 80px; resize: none"
                                            matInput
                                            formControlName="info"
                                            name="info"
                                            cols="10"
                                            rows="10"
                                            (focusout)="setInputVal('info', $event)"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
        </div>
        <div class="card mb-2">
            <fap-section-container [title]="'stock.consumed' | translate" [isTable]="true" [isAddEnabled]="true" (clicked)="getResourceInfo()" [sticky]="false">
                <items-table [items]="activity?.resources?.consumed?.items" [display]="getDisplay()"></items-table>
            </fap-section-container>
        </div>
        <div class="card mb-2">
            <fap-section-container [title]="'stock.produced' | translate" [isTable]="true" [isAddEnabled]="true" (clicked)="getResourceInfo()" [sticky]="false">
                <items-table [items]="activity?.resources?.produced?.items" [display]="getDisplay()"></items-table>
            </fap-section-container>
        </div>
        <div class="card" *ngIf="objectId && entityInfo">
            <fap-attributes
                [entityRelation]="'activity'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="objectId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>
        </div>
        <div class="btns mt-4 d-flex justify-content-between">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="activity">
                    <i class="fa fa-trash" *ngIf="!isBaselineActivity" (click)="deleteActivity.emit(activity?.id)"></i>
                    <i class="fa fa-trash" *ngIf="isBaselineActivity" (click)="deleteBaselineActivity.emit(activity?.id)"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-end">
                <button class="btn mr-2" (click)="submitForm()">{{ 'translations.save' | translate }}</button>
                <button class="btn" (click)="closeModal.emit(); activityForm.reset()">{{ 'translations.cancel' | translate }}</button>
            </div>
        </div>
    </div>

    <fap-modal
        #addActivityTypeModal
        [inputPrimaryButtonInterface]="
            addActivityTypeModalButtonPrimaryInterface
        "
        [inputSecondaryButtonInterface]="
            addActivityTypeModalButtonSecondaryInterface
        "
        [inputTitle]="'activity.addEditActivityType' | translate"
    >
        <form [formGroup]="activityTypeForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput', activityType, addEditTranslationsModal, 'activityTypeForm')">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput/>
                    <mat-error *ngIf="activityTypeForm.controls['name'].errors?.required">
                      <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <fap-add-edit-delete-select
                    inputName="{{ 'stock.formType' | translate }}"
                    class="w-100"
                    [formControl]="activityTypeForm.controls['entity_form']"
                    name="formType"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="formTypes"
                    [closeCombo]="closeCombo"
                    [isEditable]="false"
                    [isDeleteable]="false"
                    [createEnabled]="false"
                    (clicked)="setFormType($event)"
                >
                </fap-add-edit-delete-select>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "notes.color" | translate
                    }}</mat-label>
                    <input
                        class="w-100"
                        matInput
                        type="color"
                        formControlName="color"
                    />
                    <mat-error
                        *ngIf="
                            activityTypeForm.controls['color'].errors?.required
                        "
                    >
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <div class="overlay" *ngIf="isPerson" (click)="isPerson = false"></div>
    <div class="popup" *ngIf="isPerson">
        <fap-people-card-list
            [persons]="globalRegistry?.systemData?.persons"
            (closeModal)="closePopup($event)"
        ></fap-people-card-list>
    </div>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.activityType)" 
        [prefix]="prefix" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditNameTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.activity)" 
        [prefix]="'ACTIVITY_'" 
        [label]="label"
        [translation]="nameT"
        [group]="'activities'"
        (submitTranslation)="onAddUpdateNameTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditItemModal [inputTitle]="'stock.addItem' | translate" [showFooter]="false">
    <form [formGroup]="itemForm">
        <div class="form-group">
            <div class="row align-items-baseline">
                <div class="col-6">
                    <div class="input-group w-100">
                            <mat-form-field (click)="getStockItems()">
                                <mat-label>{{"stock.stockItems" | translate}}</mat-label>
                                <mat-select name="product" required>
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeFilter8 placeholder="Search">
                                    </mat-form-field>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let stockItem of stockItems | filter : typeFilter8.value" [value]="stockItem.id" (click)="getStockResources(stockItem.id)">
                                        <span class="mat-option-text">
                                        <div class="name">
                                            <!-- <span style="display: inline-block; color: gray; width: 35px;">{{stockItem?.product?.id}}.</span> -->
                                            <span style="display: inline-block; width: calc(100% - 35px);">{{ stockItem?.product?.label?.name }} </span></div>
                                            <div class="unt">
                                                <b>{{stockItem?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                            </div>
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                            </mat-form-field>
                    </div>
                </div>

                <div class="col-6" [ngClass]="{ 'hide': entries.length == 0 }">
                    <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.entry" | translate}}</mat-label>
                                <mat-select formControlName="product" name="product" required>
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeFilter4 placeholder="Search">
                                    </mat-form-field>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let entry of entries | filter : typeFilter4.value" [value]="entry.id" (click)="setEntry(entry)">
                                        <span class="mat-option-text">
                                            <div class="name clearfix full_width d-flex no_bg">
                                                <div class="nme">
                                                    <!-- <span style="color: gray">{{entry?.id}}.</span> -->
                                                      {{ entry?.product?.label?.name }}
                                                </div> 
                                                <div class="amt d-flex no_bg">
                                                    <div class="quty">
                                                        {{ entry?.remains_quantity | number: '1.0-2'}}
                                                    </div> 
                                                    <div class="unt">
                                                        <b>{{ entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                                <mat-error *ngIf="itemForm.controls['product'].errors?.required">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                    </div>
                </div>

                <div class="d-flex col-12 w-100"><h4 class="small_title">{{'stock.consumed' | translate}}</h4></div>
                <div class="d-flex posrltv custom_border col-6">
                        <div class="col-9 p-0">
                            <div class="input-group w-100">
                                <mat-form-field>
                                    <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                    <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                                </mat-form-field>
                                <mat-error *ngIf="itemForm.controls['quantity'].value > max">
                                    <strong>{{'setMaxValue' | translate}} {{max}}</strong>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-3" style="padding: 0;
                            
                            width: auto;
                            margin-bottom: 17px;
                            " *ngIf="stockUnit">
                        <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                            <b style="display: inline-block; margin-top: 22px;" *ngIf="unit?.id === stockUnit">{{unit?.shortName}}</b>
                            <ng-container *ngIf="unit?.id !== stockUnit">
                                <ng-container *ngFor="let childUnit of unit?.children">
                                    <b style="display: inline-block; margin-top: 22px;" *ngIf="childUnit?.id === stockUnit">{{childUnit?.shortName}}</b> 
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="abs error" *ngIf="entries.length && !stockUnit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>

                <div class="d-flex posrltv custom_border col-6">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="unitPrice">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 20px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>

                <div class="d-flex col-12 w-100"><h4 class="small_title">{{'stock.produced' | translate}}</h4></div>
                <div class="d-flex posrltv custom_border col-6">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                            </mat-form-field>
                            <mat-error *ngIf="itemForm.controls['quantity'].value > max">
                                <strong>{{'setMaxValue' | translate}} {{max}}</strong>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                        
                        width: auto;
                        margin-bottom: 17px;
                        " *ngIf="stockUnit">
                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                        <b style="display: inline-block; margin-top: 22px;" *ngIf="unit?.id === stockUnit">{{unit?.shortName}}</b>
                        <ng-container *ngIf="unit?.id !== stockUnit">
                            <ng-container *ngFor="let childUnit of unit?.children">
                                <b style="display: inline-block; margin-top: 22px;" *ngIf="childUnit?.id === stockUnit">{{childUnit?.shortName}}</b> 
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="abs error" *ngIf="entries.length && !stockUnit">
                    <span>{{'stock.specifyProductUnit' | translate}}</span>
                </div>
                </div>

                <div class="d-flex posrltv custom_border col-6">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="unitPrice">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 20px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>

            </div>
            <div class="row align-items-baseline">
                <div class="d-flex posrltv custom_border col-6">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.vat" | translate}}</mat-label>
                                <mat-select class="select" style="text-align: right" required formControlName="vat">
                                            <div class="scroll_wrap">
                                                <mat-option style="height: 0!important;"></mat-option>
                                                <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                            </div>
                                        </mat-select>
                            </mat-form-field>
                            <!-- <mat-error *ngIf="itemForm.controls['vat'].value == ''">
                                <strong>Please enter VAT</strong>
                            </mat-error> -->
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: block; margin-top: 20px; text-align: right;">%</b>
                    </div>
                </div>
                <div class="input-group align-items-baseline w-100 col-6">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.info' | translate }}</mat-label>
                        <input type="text" matInput formControlName="info">
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
                <button class="btn mr-2" (click)="addRow()">{{"translations.save" | translate}}</button>
                <button class="btn" (click)="addEditItemModal.hideModal()">{{"translations.cancel" | translate}}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditPlanModal [inputTitle]="'auth.company.addEditPlan' | translate" [showFooter]="false" [inputIsLarge]="true">
    <fap-add-edit-plan
        [plan]="plan"
        [planId]="planId"
        [planTypes]="planTypes"
        (addPlan)="addPlan($event)"
        (updatePlan)="updatePlan($event)"
        (closeModal)="addEditPlanModal.hideModal()"
    ></fap-add-edit-plan>
</fap-modal>

<fap-modal #addEditSchedulerModal [inputTitle]="'scheduler.scheduler' | translate" [showFooter]="false" [inputIsMedium]="true">
    <fap-add-edit-scheduler 
        [scheduler]="scheduler"
        [schedulerId]="schedulerId"
        (addScheduler)="addedScheduler($event);"
        (updateScheduler)="updatedScheduler($event);"
        (closeModal)="addEditSchedulerModal.hideModal()"
    ></fap-add-edit-scheduler>
</fap-modal>

<fap-modal #addEditResourceModal [inputTitle]="'Resource Document'" [showFooter]="false" [inputIsLarge]="true" (outputOnHide)="handleClose()">
    <fap-stock-bill *ngIf="(isModalView === true || false) && isModalView != null"
        [isNewDoc]="isNewDoc"
        [docType]="docType"
        [docId]="docId"
        [isModalView]="isModalView"
        (docUpdate)="docUpdate($event)"
        (deleteDoc)="deleteDoc($event)"
        (closeModal)="addEditResourceModal.hideModal()"
    ></fap-stock-bill>
</fap-modal>