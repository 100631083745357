<fap-header *ngIf="!isModalView">
    <fap-header-actions [title]="title"></fap-header-actions>
</fap-header>
<div class="row" id="parent">
    <div class="loader_container">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>
    <div class="col-12" *ngIf="!isLoading" [style.padding]="isModalView ? '0px' : '0 15px'">
        <div class="card">
            <fap-section-container [title]="'widget.overview' | translate" [sticky]="!isModalView">
                <form [formGroup]="billForm">
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-4">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">{{ 'stock.documentId' | translate }}</mat-label>
                                    <input type="text" matInput formControlName="documentId">
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="route.snapshot.queryParams.from !== 'activity'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100" (click)="documentDate.open()">
                                    <mat-label>
                                        {{ 'stock.date' | translate }}</mat-label>
                                    <input matInput readonly formControlName="documentDate" [matDatepicker]="documentDate">
                                    <mat-datepicker-toggle matSuffix [for]="documentDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #documentDate></mat-datepicker>
                                    <mat-error *ngIf="ifControlHasError('documentDate','required')">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'" style="margin-bottom: -1rem;">
                                <fap-add-edit-delete-select *ngIf="docType === 'invoice_in'"
                                    inputName="{{ 'party.partyTo' | translate }}"
                                    class="w-100"
                                    [formControl]="billForm.controls['partyTo']"
                                    name="partyTo"
                                    [inputIsRequired]="true"
                                    [apiSearchEnabled]="true"
                                    [inputValueFieldName]="'id'"
                                    [inputNameFieldName]="'name'"
                                    [inputSelectValues]="ownParties"
                                    (click)="getOwnParties()"
                                    [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                                    (outputOnDelete)="onDeleteBillPartyTo($event, 'partyTo')"
                                    (outputOnScroll)="scrollOwnParties()"
                                    (outputOnCreate)="addPartyModal()"
                                    (outputOnSearch)="filterOwnParties($event)"
                                    (selectionChange)="billForm.get('partyTo').setValue($event)"
                                    (outputOnEdit)="editParty($event)">
                                </fap-add-edit-delete-select>
                                <fap-add-edit-delete-select *ngIf="docType === 'invoice_out'"
                                    inputName="{{ 'party.partyTo' | translate }}"
                                    class="w-100"
                                    [formControl]="billForm.controls['partyTo']"
                                    name="partyTo"
                                    [inputIsRequired]="true"
                                    [apiSearchEnabled]="true"
                                    [inputValueFieldName]="'id'"
                                    [inputNameFieldName]="'name'"
                                    [inputSelectValues]="parties"
                                    (click)="getParties()"
                                    [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                                    (outputOnDelete)="onDeleteBillPartyTo($event, 'partyTo')"
                                    (outputOnScroll)="scrollParties()"
                                    (outputOnCreate)="addPartyModal()"
                                    (selectionChange)="billForm.get('partyTo').setValue($event)"
                                    (outputOnSearch)="filterParties($event)"
                                    (outputOnEdit)="editParty($event)">
                                </fap-add-edit-delete-select>
                            </div>
                            <div class="col-4" *ngIf="docType !== 'internal_note'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.depositFrom' | translate }}</mat-label>
                                    <mat-select required formControlName="farm">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">
                                            {{ farm.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-2" *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">{{ 'stock.vat' | translate }}</mat-label>
                                    <!-- <input type="number" matInput formControlName="vat" (keyup)="calculateValues()"> -->
                                    <mat-select style="text-align: right" class="select" required formControlName="vat" (selectionChange)="calculateValues($event)">
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-2" *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label>{{ 'stock.currency' | translate }}</mat-label>
                                    <mat-select #mySelect class="select" required formControlName="currency" (selectionChange)="currentCurrency($event)">
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                            <mat-form-field appearance="fill" class="w-100">
                                                <input matInput #u1Filter placeholder="Search">
                                            </mat-form-field>
                                            <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                                        </div>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                        <mat-optgroup class="noteIcons currs" *ngFor="let parent of globalRegistry?.systemData?.units | filter : u1Filter.value">
                                            <div class="frow w-100" *ngIf="parent?.genre === '$'">
                                                <div class="barr">
                                                <div class="type-name">
                                                        <span *ngIf="parent?.nameT">
                                                            <span *ngIf="getTranslation(parent?.nameT) === '' || getTranslation(parent?.nameT) == null || getTranslation(parent?.nameT) == undefined ">{{parent?.name}}</span>
                                                            <span *ngIf="getTranslation(parent?.nameT) !== ''">{{getTranslation(parent?.nameT)}}</span>
                                                        </span>
                                                        <span *ngIf="!parent?.nameT">{{parent.name}}</span>
                                                </div>
                                                <div class="actions d-flex text-center">
                                                    <i [ngClass]="{ 'disabled': parent?.locked }" class="fa fa-edit align-self-center f-20"  (click)="selectUnit(parent);$event.stopPropagation()"></i>
                                                    <i [ngClass]="{ 'disabled': parent?.locked }" class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteUnit(parent?.id);$event.stopPropagation()"></i>
                                                </div>
                                            </div>
                                            <div class="slct">
                                                <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                                <div class="type-name">
                                                    <span *ngIf="child?.nameT">
                                                        <span *ngIf="getTranslation(child?.nameT) === '' || getTranslation(child?.nameT) == null || getTranslation(child?.nameT) == undefined ">{{child?.name}}</span>
                                                        <span *ngIf="getTranslation(child?.nameT) !== ''">{{getTranslation(child?.nameT)}}</span>
                                                    </span>
                                                    <span *ngIf="!child?.nameT">{{child.name}}</span>
                                                </div>
                                                <div class="actions d-flex text-center">
                                                    <i [ngClass]="{ 'disabled': child?.locked }" class="fa fa-edit align-self-center f-20"  (click)="selectUnit(child, parent);$event.stopPropagation()"></i>
                                                    <i [ngClass]="{ 'disabled': child?.locked }" class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteUnit(child?.id);$event.stopPropagation()"></i>
                                                </div>
                                            </mat-option>
                                            </div>
                                        </div>
                                        
                                        </mat-optgroup>
                                    </div>
                                    </mat-select>
                                    <mat-error *ngIf="ifControlHasError('unit','required')">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="col-4" [ngClass]="{'dbld': defaultCurrency === selectedUnit?.id}" *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label>{{ 'stock.exchange' | translate }}</mat-label>
                                    <mat-select formControlName="exchange" name="exchange" #mys (selectionChange)="setExchange($event)">
                                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #typeFilter100 placeholder="Search">
                                        </mat-form-field>
                                        <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                                            <i class="fa fa-search f-20"></i>
                                        </mat-label>
                                        <div class="input-group-append pl-1" (click)="showAddExchangeModal()">
                                            <span class="input-group-text">
                                                <!-- <i class="icofont icofont icofont-plus"></i> -->
                                                <fap-round-button></fap-round-button>
                                             </span>
                                        </div>
                                    </div>
                                    <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let rate of rates | filter : typeFilter100.value" [value]="rate">
                                            <span class="mat-option-text">
                                            <div class="name ddd" [attr.date]="rate.date | date: 'MMM dd yyyy'" [attr.name]="rate.name">
                                                <div class="rexc">
                                                    <span>{{rate?.from_qty}} <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                                    <b style="font-weight: normal" class="unit" *ngIf="unit?.id === rate?.from_unit">{{unit?.shortName}}</b>
                                                    <ng-container *ngIf="unit?.id !== rate?.from_unit">
                                                        <ng-container *ngFor="let childUnit of unit?.children">
                                                            <b style="font-weight: normal" class="unit" *ngIf="childUnit?.id === rate?.from_unit">{{childUnit?.shortName}}</b> 
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container> = </span> <b>{{rate.to_qty}} <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                                    <b class="unit" *ngIf="unit?.id === rate?.to_unit">{{unit?.shortName}}</b>
                                                    <ng-container *ngIf="unit?.id !== rate?.to_unit">
                                                        <ng-container *ngFor="let childUnit of unit?.children">
                                                            <b class="unit" *ngIf="childUnit?.id === rate?.to_unit">{{childUnit?.shortName}}</b> 
                                                        </ng-container>
                                                    </ng-container>
                                                    </ng-container></b>
                                                    </div>
                                            </div>
                                            <!-- <div class="actions">
                                                <i class="fa fa-edit" (click)="rateEdit(rate); $event.stopPropagation()"></i>
                                                <i class="fa fa-trash" (click)="rateDelete(rate?.id); $event.stopPropagation()"></i>
                                            </div> -->
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="col-4">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100" (click)="getPersons()">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.receiver' | translate }}</mat-label>
                                    <mat-select formControlName="receivedBy" (infiniteScroll)="scrollPersons()" [complete]="offset === persons.length" msInfiniteScroll>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #personFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap" (scroll)="onPersonScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let person of persons | filter : personFilter.value" [value]="person.id">
                                            {{person?.firstName}} {{person?.lastName}} {{person?.first_name}} {{person?.last_name}}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                    <mat-error *ngIf="ifControlHasError('receivedBy','required')">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="docType !== 'invoice_out'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100" (click)="receivedDate.open()">
                                    <mat-label>
                                        {{ 'stock.receiveDate' | translate }}</mat-label>
                                    <input matInput readonly formControlName="receivedDate" [matDatepicker]="receivedDate">
                                    <mat-datepicker-toggle matSuffix [for]="receivedDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #receivedDate></mat-datepicker>
                                    <mat-error *ngIf="ifControlHasError('receivedDate','required')">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            
                            <div class="col-4" *ngIf="docType === 'internal_note' ">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.depositFrom' | translate }}</mat-label>
                                    <mat-select required formControlName="farm">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">
                                            {{ farm.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            
                            <div class="col-4" *ngIf="docType === 'internal_note' ">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.depositTo' | translate }}</mat-label>
                                    <mat-select required formControlName="farmTo">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter1 placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let farm of farms | filter : farmFilter1.value" [value]="farm.id">
                                            {{ farm.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            </div>
                            <!-- <div class="col-4" *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100" (click)="filterInvoices()">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.contract' | translate }}</mat-label>
                                    <mat-select formControlName="receivedBy" (infiniteScroll)="scrollPersons()" [complete]="offset === contractDocs.length" msInfiniteScroll>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #contractFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap" (scroll)="onPersonScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let contract of contractDocs | filter : contractFilter.value" [value]="contract.id">
                                            {{contract.id}}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                    <mat-error *ngIf="ifControlHasError('receivedBy','required')">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div> -->
                            <div class="row align-items-baseline">
                            <div class="col-8">
                                    <div
                                        class="input-group align-items-baseline w-100"
                                    >
                                    <mat-form-field class="w-100">
                                        <mat-label class="col-form-label">{{ 'stock.info' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="info">
                                    </mat-form-field>
                                    </div>
                            </div>
                            <div class="col-4" *ngIf="docType !== 'production_note' && docType !== 'estimation_note'">
                                <button class="btn e-invoice" *ngIf="docType === 'invoice_out'" (click)="updateEInvoice()">{{'stock.einvoice' | translate}}</button>
                            </div>
                            <!-- <div class="col-4 opac" *ngIf="docType == 'internal_note' ">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">{{ 'stock.vat' | translate }}</mat-label>
                                    <input type="number" matInput formControlName="vat" (keyup)="calculateValues()">
                                    <mat-select class="select" style="text-align: right" required formControlName="vat" (selectionChange)="calculateValues($event)">
                                        <div class="scroll_wrap">
                                            <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div> -->
                            <ng-container *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                            <div class="col-4 bg-gray">
                                <div class="input-group align-items-baseline w-100 custom_border">
                                <mat-form-field class="custom_width no_border">
                                    <mat-label class="col-form-label">{{ 'stock.total' | translate }}</mat-label>
                                    <input type="text" class="no-border" matInput formControlName="total" readonly>
                                </mat-form-field>
                                <div class="selected_unit">
                                    {{selectedUnit?.shortName}}
                                </div>
                                </div>
                            </div>
                            <div class="col-4 bg-gray">
                                <div class="input-group align-items-baseline w-100 custom_border">
                                <mat-form-field class="custom_width no_border">
                                    <mat-label class="col-form-label">{{ 'stock.vat' | translate }}</mat-label>
                                    <input type="text" class="no-border" matInput formControlName="totalVat" readonly>
                                </mat-form-field>
                                <div class="selected_unit">
                                    {{selectedUnit?.shortName}}
                                </div>
                                </div>
                            </div>
                            <div class="col-4 bg-gray">
                                <div class="input-group align-items-baseline w-100 custom_border">
                                <mat-form-field class="custom_width no_border">
                                    <mat-label class="col-form-label">{{ 'stock.grandTotal' | translate }}</mat-label>
                                    <input type="text" class="no-border" matInput formControlName="grandTotal" readonly>
                                </mat-form-field>
                                <div class="selected_unit">
                                    {{selectedUnit?.shortName}}
                                </div>
                                </div>
                            </div>
                        </ng-container>

                            <ng-container *ngIf="docType === 'internal_note' ">
                                <div class="col-4 bg-gray">
                                    <div class="input-group align-items-baseline w-100 custom_border">
                                    <mat-form-field class="custom_width no_border">
                                        <mat-label class="col-form-label">{{ 'stock.total' | translate }}</mat-label>
                                        <input type="text" class="no-border" matInput formControlName="total" readonly>
                                    </mat-form-field>
                                    <div class="selected_unit">
                                        {{selectedUnit?.shortName}}
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4 bg-gray">
                                    <div class="input-group align-items-baseline w-100 custom_border">
                                    <mat-form-field class="custom_width no_border">
                                        <mat-label class="col-form-label">{{ 'stock.vat' | translate }}</mat-label>
                                        <input type="text" class="no-border" matInput formControlName="totalVat" readonly>
                                    </mat-form-field>
                                    <div class="selected_unit">
                                        {{selectedUnit?.shortName}}
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4 bg-gray">
                                    <div class="input-group align-items-baseline w-100 custom_border">
                                    <mat-form-field class="custom_width no_border">
                                        <mat-label class="col-form-label">{{ 'stock.grandTotal' | translate }}</mat-label>
                                        <input type="text" class="no-border" matInput formControlName="grandTotal" readonly>
                                    </mat-form-field>
                                    <div class="selected_unit">
                                        {{selectedUnit?.shortName}}
                                    </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div> 
                </form>
            </fap-section-container>
        </div>
        <div class="card mt-2" *ngIf="this.bill">
            <!-- <div class="card-header px-3 rounded-0 title_row">
                <div *ngIf="docType !== 'production_note' && docType !== 'estimation_note'">{{ "widget.items" | translate }}
                </div>
                <span *ngIf="docType !== 'production_note' && docType !== 'estimation_note'" style="margin-top: 0;" (click)="showModal()" class="theme_color"></span>
                <ng-container *ngIf="docType === 'production_note'">
                    <div>{{ "stock.consumed" | translate }}</div>
                    <span (click)="showModal()" class="theme_color"></span>
                </ng-container>
                <ng-container *ngIf="docType === 'estimation_note'">
                    <div>{{ "stock.consumed" | translate }}</div>
                    <span (click)="showProduction1Modal()" class="theme_color"></span>
                </ng-container>
            </div> -->
            <fap-section-container [title]="getTitle() | translate" 
                        [isAddEnabled]="true" [isTable]="true"
                       (clicked)="docType !== 'production_note' && docType !== 'estimation_note' ? showModal() : null" 
                       (clicked)="docType === 'production_note' ? showModal() : null" 
                       (clicked)="docType === 'estimation_note' ? showProduction1Modal() : null" [sticky]="!isModalView">
                <div class="notedit table-responsive">
                    <table class="items_table">
                        <thead>
                            <tr *ngIf="docType !== 'internal_note' && docType !== 'production_note' && docType !== 'estimation_note'">
                                <th>{{"stock.item" | translate}}</th>
                                <th>{{"stock.qty" | translate}}</th>
                                <th>{{'stock.unit' | translate}}</th>
                                <th *ngIf="docType === 'invoice_in'">{{ 'stock.unitPrice' | translate }}</th>
                                <th *ngIf="docType === 'invoice_out' || docType === 'internal_note'">
                                    {{"stock.cost" | translate}}
                                </th>
                                <th>{{"stock.totalPrice" | translate}}</th>
                                <th>{{ 'stock.vat' | translate }} %</th>
                                <th>{{ 'stock.vat' | translate }}</th>
                                <!-- <th>{{"stock.grand" | translate}} <span>{{"stock.total" | translate}}</span></th> -->
                                <th></th>
                            </tr>
                            <tr *ngIf="docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note'">
                                <th>{{"stock.item" | translate}}</th>
                                <th>{{"stock.qty" | translate}}</th>
                                <th>{{'stock.unit' | translate}}</th>
                                <th>{{"stock.cost" | translate}}</th>
                                <th>{{"stock.vat" | translate}} %</th>
                                <th style="width: 2%;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items">
                                <td>
                                <product-tag [productName]="item?.product?.label?.name" [productType]="item?.product?.label?.type"></product-tag>
                                <div class="info">{{item?.info}}</div>
                            </td>
                                <td >
                                    {{ item?.quantity | number: '1.0-2' }}
                                </td>
                                <td>
                                    <b>{{item?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                </td>                            
                                <ng-container *ngIf="docType !== 'internal_note'">
                                <td class="split" *ngIf="docType === 'invoice_in'">
                                    <b>{{item?.unitPrice | number:'1.'+digits}}</b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <span style="font-style:italic; display: block;" *ngIf="defaultCurrency != item?.currency">
                                        <b style="font-weight: normal;">({{item?.costOrig | number:'1.'+digits}}</b> <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b style="font-weight: normal;" *ngIf="unit?.id === defaultCurrency">{{unit?.shortName}})</b>
                                        <ng-container *ngIf="unit?.id !== defaultCurrency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b style="font-weight: normal;" *ngIf="childUnit?.id === defaultCurrency">{{childUnit?.shortName}})</b> 
                                            </ng-container>
                                        </ng-container>
                                        </ng-container>
                                    </span>
                                </td>
                            
                                <td class="split" *ngIf="docType === 'invoice_out'">
                                    <b style="font-weight: normal;">{{item.unitPrice | number:'1.'+digits }}</b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <span style="font-style:italic; display: block;" *ngIf="defaultCurrency != item?.currency">
                                        <b style="font-weight: normal;">({{item.costOrig | number:'1.'+digits}}</b> <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b style="font-weight: normal;" *ngIf="unit?.id === defaultCurrency">{{unit?.shortName}})</b>
                                        <ng-container *ngIf="unit?.id !== defaultCurrency">
                                                <ng-container *ngFor="let childUnit of unit?.children">
                                                    <b style="font-weight: normal;" *ngIf="childUnit?.id === defaultCurrency">{{childUnit?.shortName}})</b> 
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </span>
                                </td>
                            </ng-container>
                                <td class="split"><b style="font-weight: normal;">{{(item?.totalPrice) | number:'1.'+digits}}</b> <b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </b></td>
                                <td class="split"><b style="font-weight: normal;">{{item?.vatPercentage}}</b> <b>%</b></td>
                                <td class="split" *ngIf="docType !== 'estimation_note' && docType !== 'internal_note' && docType !== 'production_note'"><b style="font-weight: normal;">{{(getVat(item?.currency, item?.costOrig, item?.quantity, item?.unitPrice, item?.vatPercentage / 100) | number:'1.'+digits)}}</b> <b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </b></td>
                                <!-- <td class="split" *ngIf="docType !== 'estimation_note' && docType !== 'internal_note' && docType !== 'production_note'"><b style="font-weight: normal;">{{item?.grandTotal | number:'1.'+globalRegistry?.systemData?.company?.digits}}</b> <b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </b></td> -->
                                <td><i class="fa fa-trash c-pointer green" (click)="deleteItem(item?.id)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fap-section-container>
        </div>

        <div class="card mt-2" *ngIf="this.bill && (docType === 'production_note' || docType === 'estimation_note') ">
            <fap-section-container [title]="'stock.produced' | translate" 
            [isAddEnabled]="true" [isTable]="true" (clicked)="showProductionModal()" [sticky]="!isModalView">
                <div class="notedit table-responsive">
                    <table class="items_table">
                        <thead>
                            <tr>
                                <th>{{"stock.item" | translate}}</th>
                                <th>{{"stock.qty" | translate}}</th>
                                <th>{{'stock.unit' | translate}}</th>
                                <th>{{"stock.cost" | translate}}</th>
                                <th>{{"stock.vat" | translate}} %</th>
                                <th style="width: 2%;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of p_items">
                                <td><product-tag [productName]="item?.product?.label?.name" [productType]="item?.product?.label?.type"></product-tag>
                                <div class="info">{{item?.info}}</div>
                            </td>
                                <td>
                                    {{ item?.quantity | number:'1.0-2' }}
                                </td>
                                <td>
                                    <b>{{item?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                </td>
                                
                                <ng-container>
                                
                                <td class="split"><b style="font-weight: normal;">{{(item?.totalPrice) | number:'1.'+digits}}</b> <b>
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b *ngIf="unit?.id === item?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== item?.currency">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b *ngIf="childUnit?.id === item?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </b></td>
                                <td class="split"><b style="font-weight: normal;">{{item?.vatPercentage}}</b> <b>%</b></td>
                            </ng-container>
                                <td><i class="fa fa-trash c-pointer green" (click)="deletePItem(item?.id)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fap-section-container>
            <!-- <div class="card-header px-3 rounded-0 title_row">
                <div>{{ "stock.produced" | translate }}</div>
                <span (click)="showProductionModal()" class="theme_color" style="margin-top: 0;"></span>
            </div> -->
            
        </div>
        <div class="btns col-12 w-100 mt-4 d-flex justify-content-between" *ngIf="isModalView">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="bill">
                    <i class="fa fa-trash" (click)="deleteExistingDocAndItems()"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-end">
                <button class="btn mr-2" (click)="submitForm()">{{ 'translations.save' | translate }}</button>
                <button class="btn" (click)="clearDocInfo()">{{ 'translations.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<fap-modal [showFooter]="false" #addItemModal [inputTitle]="'stock.addItem' | translate">
    <form [formGroup]="itemForm">
        <div class="form-group">
            <div class="row align-items-baseline">
                <div class="col-6" *ngIf="docType === 'invoice_in'">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="productsModal.showModal(); show = true">
                            <mat-label class="col-form-label" >{{"stock.product" | translate}}</mat-label>
                            <input style="display: none;" type="text" matInput formControlName="product" readonly="true" name="product">
                            <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                                {{product?.label?.name}}
                            </span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-6" *ngIf="docType === 'internal_note' || docType === 'invoice_out' || docType === 'production_note' || docType === 'estimation_note'">
                    <div class="input-group w-100">
                            <mat-form-field (click)="getStockItems()">
                                <mat-label>{{"stock.stockItems" | translate}}</mat-label>
                                <mat-select name="product" required>
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeFilter8 placeholder="Search">
                                    </mat-form-field>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let stockItem of stockItems | filter : typeFilter8.value" [value]="stockItem.id" (click)="getStockResources(stockItem.id)">
                                        <span class="mat-option-text">
                                        <div class="name">
                                            <!-- <span style="display: inline-block; color: gray; width: 35px;">{{stockItem?.product?.id}}.</span> -->
                                            <span style="display: inline-block; width: calc(100% - 35px);">{{ stockItem?.product?.label?.name }} </span></div>
                                            <div class="unt">
                                                <b>{{stockItem?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                            </div>
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                            </mat-form-field>
                    </div>
                </div>

                <div class="col-6" *ngIf="docType === 'internal_note' || docType === 'invoice_out' || docType === 'production_note' || docType === 'estimation_note'" [ngClass]="{ 'hide': entries.length == 0 }">
                    <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.entry" | translate}}</mat-label>
                                <mat-select formControlName="product" name="product" required>
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeFilter4 placeholder="Search">
                                    </mat-form-field>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let entry of entries | filter : typeFilter4.value" [value]="entry.id" (click)="setEntry(entry)">
                                        <span class="mat-option-text">
                                            <div class="name clearfix">
                                                <div class="nme">
                                                    <!-- <span style="color: gray">{{entry?.id}}.</span> -->
                                                      {{ entry?.product?.label?.name }}
                                                </div> 
                                                <div class="amt">
                                                    <div class="quty">
                                                        {{ entry?.remains_quantity | number: '1.0-2'}}
                                                    </div> 
                                                    <div class="unt">
                                                        <b>{{ entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                                <mat-error *ngIf="itemForm.controls['product'].errors?.required">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                    </div>
                </div>

                <div class="col-6 d-flex nn posrltv custom_border" *ngIf="docType === 'invoice_in'">
                    <div class="colq">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                            </mat-form-field>
                            <mat-error *ngIf="itemForm.controls['quantity'].value > max">
                                <strong>{{'setMaxValue' | translate}} {{max}}</strong>
                            </mat-error>
                        </div>
                    </div>
                    <div class="colu" style="padding: 0;
                    
                    width: auto;
                     white-space: nowrap"  *ngIf="product && product.unit">

                        <div class="inlin">
                            <b>{{ product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                        </div>

                    </div>
                    <div class="abs error" *ngIf="product && !product.unit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>
                
                <div class="d-flex posrltv custom_border" [ngClass]="{ 'col-4': docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note' }" *ngIf="docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note'">
                        <div class="col-6 p-0">
                            <div class="input-group w-100">
                                <mat-form-field>
                                    <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                    <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                                </mat-form-field>
                                <mat-error *ngIf="itemForm.controls['quantity'].value > max">
                                    <strong>{{'setMaxValue' | translate}} {{max}}</strong>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-6" style="padding: 0;
                            
                            width: auto;
                            white-space: nowrap;
                            " *ngIf="stockUnit">
                        <!-- <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                            <b style="display: inline-block; margin-top: 21px;" *ngIf="unit?.id === stockUnit">{{unit?.shortName}}</b>
                            <ng-container *ngIf="unit?.id !== stockUnit">
                                <ng-container *ngFor="let childUnit of unit?.children">
                                    <b style="display: inline-block; margin-top: 21px;" *ngIf="childUnit?.id === stockUnit">{{childUnit?.shortName}}</b> 
                                </ng-container>
                            </ng-container>
                        </ng-container> -->
                        <b style="position: relative; top: 21px;">{{ product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                    </div>
                    <div class="abs error" *ngIf="entries.length && !stockUnit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>

                <div class="d-flex posrltv custom_border" [ngClass]="docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note' ? 'col-4' : 'col-6'">
                    <div class="col-6 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label *ngIf="docType === 'invoice_in'">{{'stock.itemPrice' | translate}}</mat-label>
                                <mat-label *ngIf="docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note'">{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="unitPrice">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 21px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>

                <div class="d-flex posrltv custom_border" [ngClass]="docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note' ? 'col-4' : 'col-6'">
                    <div class="col-9 p-0">
                    <div class="input-group w-100">
                        <mat-form-field>
                            <mat-label>{{"stock.vat" | translate}}</mat-label>
                            <mat-select class="select" style="text-align: right" required formControlName="vat">
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                        </div>
                                    </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="itemForm.controls['vat'].value == ''">
                            <strong>Please enter VAT</strong>
                        </mat-error> -->
                    </div>
                </div>
                <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: block; margin-top: 20px; text-align: right;">%</b>
                    </div>
                </div>
            </div>
            <div class="row align-items-baseline m-0">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.info' | translate }}</mat-label>
                        <input type="text" matInput formControlName="info">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button class="btn mr-2" *ngIf="docType === 'invoice_in'" (click)="addRow()">{{"translations.save" | translate}}</button>

                    <button class="btn mr-2" *ngIf="docType === 'invoice_out' || docType === 'internal_note' || docType === 'production_note' || docType === 'estimation_note'" (click)="addRow()">{{"translations.save" | translate}}</button>

                    <button class="btn" (click)="addItemModal.hideModal()">{{'translations.cancel' | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal [showFooter]="false" #addProductionItemModal [inputTitle]="'stock.addItem' | translate">
    <form [formGroup]="productionNoteItemForm">
        <div class="form-group" *ngIf="docType === 'production_note' || docType === 'estimation_note'">
            <div class="row align-items-baseline">
                <div class="col-6">
                    <div class="input-group w-100 produc_not">
                        <mat-form-field class="w-100" (click)="productsModal.showModal(); produced = 1; show = true">
                            <mat-label class="col-form-label" >{{"stock.product" | translate}}</mat-label>
                            <input style="display: none;" type="text" matInput formControlName="product" readonly="true" name="product">
                            <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                                {{product?.label?.name}}
                            </span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-6" *ngIf="docType === 'production_note'"></div>

                <div class="col-6" *ngIf="docType === 'estimation_note'">
                    <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.offer" | translate}}</mat-label>
                                <mat-select #mys2 formControlName="offer" name="offer" (selectionChange)="setOffer($event)">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeFilter25 placeholder="Search">
                                    </mat-form-field>
                                    <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label>
                                    <div class="input-group-append pl-1" (click)="showOfferModal('mys2')">
                                        <span class="input-group-text">
                                            <!-- <i class="icofont icofont icofont-plus"></i> -->
                                            <fap-round-button></fap-round-button>
                                         </span>
                                    </div>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let offer of offers | filter: typeFilter25.value" [value]="offer">
                                        <span class="mat-option-text">
                                        <div class="name">{{ offer?.name }}</div>
                                        <!-- <div class="actions">
                                            <i class="fa fa-edit" (click)="offerEdit(offer); $event.stopPropagation()"></i>
                                            <i class="fa fa-trash" (click)="offerDelete(offer?.id); $event.stopPropagation()"></i>
                                        </div> -->
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                            </mat-form-field>
                    </div>
                </div>

               <div class="col-4 d-flex nn posrltv custom_border">
                    <div class="colq">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="colu" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                     white-space: nowrap;" *ngIf="product && product.unit">

                        <div class="inlin">
                            <b>{{product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                        </div>

                    </div>
                    <div class="abs error" *ngIf="product && !product.unit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>
                
                <div class="d-flex posrltv col-4 custom_border">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                            
                                <mat-label>{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="unitPrice">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 21px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>

                <div class="d-flex posrltv col-4 custom_border">
                    <div class="col-9 p-0">
                    <div class="input-group w-100">
                        <mat-form-field>
                            <mat-label>{{"stock.vat" | translate}}</mat-label>
                            <mat-select class="select" required formControlName="vat" style="text-align: right">
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="itemForm.controls['vat'].value == ''">
                            <strong>Please enter VAT</strong>
                        </mat-error> -->
                    </div>
                </div>
                <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: block; margin-top: 20px; text-align: right;">%</b>
                    </div>
                </div>
            </div>
            <div class="row align-items-baseline m-0">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.info' | translate }}</mat-label>
                        <input type="text" matInput formControlName="info">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button class="btn mr-2" (click)="addPRow(1)" [disabled]="!productionNoteItemForm.controls['quantity'].value">{{"translations.save" | translate}}</button>
                    <button class="btn" (click)="addProductionItemModal.hideModal()">{{"translations.cancel" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal [showFooter]="false" #addProductionUsedModal [inputTitle]="'stock.addItem' | translate">
    <form [formGroup]="productionNoteItemForm">
        <div class="form-group" *ngIf="docType === 'production_note' || docType === 'estimation_note'">
            <div class="row align-items-baseline">
                <div class="col-6">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="productsModal.showModal(); show = true">
                            <mat-label class="col-form-label" >{{"stock.product" | translate}}</mat-label>
                            <input style="display: none;" type="text" matInput formControlName="product" readonly="true" name="product">
                            <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                                {{product?.label?.name}}
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6" *ngIf="docType !== 'estimation_note'">
                    <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{"stock.entry" | translate}}</mat-label>
                                <mat-select formControlName="entry" name="entry">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <input matInput #typeeFilter placeholder="Search">
                                    </mat-form-field>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option class="noteIcons" *ngFor="let entry of entries | filter : typeeFilter.value" [value]="entry.id" (click)="setEntry(entry)">
                                        <span class="mat-option-text">
                                            <div class="name clearfix">
                                                <div class="nme">
                                                    <span style="color: gray">{{entry?.id}}.</span>  {{ entry?.product?.label?.name }}
                                                </div> 
                                                <div class="amt">
                                                    <div class="quty">
                                                        {{ entry?.remains_quantity | number: '1.0-2'}}
                                                    </div> 
                                                    <div class="unt">
                                                        <div class="inlin">
                                                            <b>{{ entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </mat-option>
                                </div>
                                </mat-select>
                                <mat-error *ngIf="productionNoteItemForm.controls['product'].errors?.required">
                                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                    </div>
                </div>

                <div class="col-6" *ngIf="docType === 'estimation_note'">
                    <div class="input-group w-100">
                        <mat-form-field>
                            <mat-label>{{"stock.offer" | translate}}</mat-label>
                            <mat-select #mys1 formControlName="offer" name="offer" (selectionChange)="setOffer($event)">
                                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #typeFilter25 placeholder="Search">
                                </mat-form-field>
                                <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                                    <i class="fa fa-search f-20"></i>
                                </mat-label>
                                <div class="input-group-append pl-1" (click)="showOfferModal('mys1')">
                                    <span class="input-group-text">
                                        <!-- <i class="icofont icofont icofont-plus"></i> -->
                                        <fap-round-button></fap-round-button>
                                     </span>
                                </div>
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option class="noteIcons" *ngFor="let offer of offers | filter: typeFilter25.value" [value]="offer.id">
                                    <span class="mat-option-text">
                                    <div class="name clrfx">
                                        <div class="w-100 flex">
                                        <div class="off-left">
                                            {{offer?.date | date: "MMM dd yyyy"}}
                                        </div>
                                        <div class="off-center">
                                            <ng-container *ngFor="let party of parties">
                                                <ng-container *ngIf="party.id == offer.supplier">{{party?.name}}</ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="off-right">
                                            <div style="display: flex;">
                                            {{offer?.quantity}} 
                                            <div class="unt">
                                                <div class="inlin">
                                                   <b>{{ product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                                                </div>
                                            </div>
                                             <b style="color: #555; padding-left: 5px;"> - {{offer?.price}} {{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <!-- <div class="actions">
                                        <i class="fa fa-edit" (click)="offerEdit(offer); $event.stopPropagation()"></i>
                                        <i class="fa fa-trash" (click)="offerDelete(offer?.id); $event.stopPropagation()"></i>
                                    </div> -->
                                    </span>
                                </mat-option>
                            </div>
                            </mat-select>
                            <mat-error *ngIf="productionNoteItemForm.controls['offer'].errors?.required">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-4 d-flex nn posrltv custom_border">
                    <div class="colq">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="colu" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    " *ngIf="product && product.unit">

                        <div class="inlin">
                            {{product | getProductUnit: globalRegistry?.systemData?.unitsMap}}
                        </div>

                    </div>
                    <div class="abs error" *ngIf="product && !product.unit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>
                
                <div class="d-flex posrltv col-4 custom_border">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                            
                                <mat-label>{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="unitPrice">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 21px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>

                <div class="d-flex posrltv col-4 custom_border">
                    <div class="col-9 p-0">
                    <div class="input-group w-100">
                        <mat-form-field>
                            <mat-label>{{"stock.vat" | translate}}</mat-label>
                            <mat-select class="select" required formControlName="vat" style="text-align: right" (selected)="availableVAT[0]">
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-option *ngFor="let vat of availableVAT" [value]="vat">{{vat}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="itemForm.controls['vat'].value == ''">
                            <strong>Please enter VAT</strong>
                        </mat-error> -->
                    </div>
                </div>
                <div class="col-3" style="padding: 0; margin-bottom: 17px; ">
                        <b style="display: block; margin-top: 20px; text-align: right;">%</b>
                    </div>
                </div>
            </div>
            <div class="row align-items-baseline m-0">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.info' | translate }}</mat-label>
                        <input type="text" matInput formControlName="info">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button class="btn mr-2" (click)="addPRow(0)" [disabled]="!productionNoteItemForm.controls['quantity'].value">{{"translations.save" | translate}}</button>
                    <button class="btn" (click)="addProductionUsedModal.hideModal()">{{"translations.cancel" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal [showFooter]="false" #addExchangeModal [inputTitle]="'stock.addExchange' | translate">
    <form [formGroup]="addEditExchangeForm">
        <div class="form-group">
            <div class="row align-items-baseline">
                <div class="col-6">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="date.open()">
                            <mat-label>
                                {{ 'stock.date' | translate }}</mat-label>
                            <input matInput readonly formControlName="date" [matDatepicker]="date">
                            <mat-datepicker-toggle matSuffix [for]="date">
                            </mat-datepicker-toggle>
                            <mat-datepicker #date></mat-datepicker>
                            <mat-error *ngIf="ifControlHasError('date','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>                    
                    </div>
                </div>
                <div class="col-6">
                    <div
                        class="input-group align-items-baseline w-100"
                    >
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.name' | translate }}</mat-label>
                        <input type="text" matInput formControlName="name">
                    </mat-form-field>
                    </div>
                </div>
                    <div class="col-6 from d-flex custom_border">
                        <div class="col-6">
                            <div
                                class="input-group align-items-baseline w-100"
                            >
                            <mat-form-field class="w-100">
                                <mat-label class="col-form-label">{{ 'stock.fromQty' | translate }}</mat-label>
                                <input type="text" matInput formControlName="fromQty">
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group align-items-baseline w-100">
                            <mat-form-field class="w-100">
                                <mat-label>{{ 'stock.fromUnit' | translate }}</mat-label>
                                <mat-select #mySelect class="select" required formControlName="fromUnit">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #u2Filter placeholder="Search">
                                        </mat-form-field>
                                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                                    </div>
                                    <div class="scroll_wrap">
                                        <div class="slct">
                                            <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let unit of exchangeCurrencies | filter : u2Filter.value" [value]="unit?.id">
                                            <div class="type-name ddd" [attr.name]="unit.name">
                                                <span>{{unit.shortName}}</span>
                                            </div>
                                            <div class="actions d-flex text-center">
                                                <i [ngClass]="{ 'disabled': unit?.locked }" class="fa fa-edit align-self-center f-20"  (click)="selectUnit(unit, parent);$event.stopPropagation()"></i>
                                                <i [ngClass]="{ 'disabled': unit?.locked }" class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteUnit(unit?.id);$event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                        </div>
                                    </div>
                                </mat-select>
                                <mat-error *ngIf="ifControlHasError('fromUnit','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 to d-flex custom_border">
                        <div class="col-6">
                            <div
                                class="input-group align-items-baseline w-100"
                            >
                            <mat-form-field class="w-100">
                                <mat-label class="col-form-label">{{ 'stock.toQty' | translate }}</mat-label>
                                <input type="text" matInput formControlName="toQty">
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group align-items-baseline w-100">
                            <mat-form-field class="w-100">
                                <mat-label>{{ 'stock.toUnit' | translate }}</mat-label>
                                <mat-select #mySelect class="select" required formControlName="toUnit">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #u1Filter placeholder="Search">
                                        </mat-form-field>
                                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                                    </div>
                                    <div class="scroll_wrap">
                                        <div class="slct">
                                            <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let unit of exchangeCurrencies | filter : u1Filter.value" [value]="unit?.id">
                                            <div class="type-name ddd" [attr.name]="unit.name">
                                                <span>{{unit.shortName}}</span>
                                            </div>
                                            <div class="actions d-flex text-center">
                                                <i [ngClass]="{ 'disabled': unit?.locked }" class="fa fa-edit align-self-center f-20"  (click)="selectUnit(unit, parent);$event.stopPropagation()"></i>
                                                <i [ngClass]="{ 'disabled': unit?.locked }" class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteUnit(unit?.id);$event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                        </div>
                                    </div>
                                </mat-select>
                                <mat-error *ngIf="ifControlHasError('toUnit','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button class="btn mr-2" (click)="addExchange()" [disabled]="addEditExchangeForm.invalid">{{"translations.save" | translate}}</button>
                    <button class="btn" (click)="addExchangeModal.hideModal()">{{"translations.cancel" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal [showFooter]="false" #addOfferModal [inputTitle]="'stock.addOffer' | translate">
    <form [formGroup]="addEditOfferForm">
        <div class="form-group">
            <div class="row align-items-baseline">
                <div class="col-12">
                    <div
                        class="input-group align-items-baseline w-100"
                    >
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.offerID' | translate }}</mat-label>
                        <input type="text" matInput formControlName="name">
                    </mat-form-field>
                    </div>
                </div>
                <div class="col-6">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="odate.open()">
                            <mat-label>
                                {{ 'stock.offerDate' | translate }}</mat-label>
                            <input matInput readonly formControlName="date" [matDatepicker]="odate">
                            <mat-datepicker-toggle matSuffix [for]="odate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #odate></mat-datepicker>
                            <mat-error *ngIf="ifControlHasError('date','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>                    
                    </div>
                </div>
                <div class="col-6">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="expire.open()">
                            <mat-label>
                                {{ 'stock.expire' | translate }}</mat-label>
                            <input matInput readonly formControlName="expire" [matDatepicker]="expire">
                            <mat-datepicker-toggle matSuffix [for]="expire">
                            </mat-datepicker-toggle>
                            <mat-datepicker #expire></mat-datepicker>
                            <mat-error *ngIf="ifControlHasError('expire','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>                    
                    </div>
                </div>
                <div class="col-12" style="margin-bottom: -15px;">
                    <fap-add-edit-delete-select 
                                    inputName="{{ 'stock.supplier' | translate }}"
                                    class="w-100"
                                    [formControl]="addEditOfferForm.controls['supplier']"
                                    name="supplier"
                                    [inputIsRequired]="true"
                                    [apiSearchEnabled]="true"
                                    [inputValueFieldName]="'id'"
                                    [inputNameFieldName]="'name'"
                                    [inputSelectValues]="parties"
                                    (click)="getParties()"
                                    (selectionChange)="addEditOfferForm.get('supplier').setValue($event)"
                                    [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                                    (outputOnDelete)="onDeleteParty($event, 'supplier')"
                                    (outputOnCreate)="addPartyModal()"
                                    (outputOnScroll)="scrollParties()"
                                    (outputOnSearch)="filterParties($event)"
                                    (outputOnEdit)="editParty($event)">
                                </fap-add-edit-delete-select>
                </div>
                <div class="col-12">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" (click)="productsModal.showModal(); show = true">
                            <mat-label class="col-form-label" >{{"stock.product" | translate}}</mat-label>
                            <input style="display: none;" type="text" matInput formControlName="product" readonly="true" name="product">
                            <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                                {{product?.label?.name}}
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6 d-flex nn posrltv custom_border">
                    <div class="colq">
                        <div class="input-group w-100">
                            <mat-form-field>
                                <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="colu" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    " *ngIf="product && product.unit">

                        <div class="inlin">
                            {{product | getProductUnit: globalRegistry?.systemData?.unitsMap}}
                        </div>

                    </div>
                    <div class="abs error" *ngIf="product && !product.unit">
                        <span>{{'stock.specifyProductUnit' | translate}}</span>
                    </div>
                </div>
                
                <div class="d-flex posrltv col-6 posrltv custom_border">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field>
                            
                                <mat-label>{{"stock.cost" | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="price">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                    
                    width: auto;
                    margin-bottom: 17px;
                    ">
                        <b style="display: inline-block; margin-top: 21px;">{{selectedUnit?.shortName ? selectedUnit?.shortName : ''}}</b>
                    </div>
                </div>
                <div class="col-6">
                    <div
                        class="input-group align-items-baseline w-100"
                    >
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.subscription' | translate }}</mat-label>
                        <input type="text" matInput formControlName="subscription">
                    </mat-form-field>
                    </div>
                </div>
                <div class="col-6">
                    <div
                        class="input-group align-items-baseline w-100"
                    >
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.supplierCode' | translate }}</mat-label>
                        <input type="text" matInput formControlName="supplierCode">
                    </mat-form-field>
                    </div>
                </div>
                <div class="col-12">
                    <div
                        class="input-group align-items-baseline w-100"
                    >
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'stock.supplierUrl' | translate }}</mat-label>
                        <input type="text" matInput formControlName="supplierUrl">
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button class="btn mr-2" (click)="addOffer()">{{"translations.save" | translate}}</button>
                    <button class="btn" (click)="addOfferModal.hideModal()">{{"translations.cancel" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditUnitModal 
inputTitle="{{ 'units.addEditUnit' | translate }}">
<form [formGroup]="unitForm" class="unit_form col-md-12 p-0">
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.name' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.shortName' | translate }}</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('shortName','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.genre' | translate }}</mat-label>
            <!-- <input matInput formControlName="genre"> -->
            <mat-select required formControlName="genre">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #genreFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let genre of this.globalRegistry.genres | filter : genreFilter.value" [value]="genre?.indicator">
                    <span>{{ genre?.name }} ( <b>{{genre?.indicator}}</b> )</span>
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('genre','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group" *ngIf="units">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentUnit && currentUnit">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #unitFilter placeholder="Search">
                </mat-form-field>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value" [value]="parent?.id">
                    {{ parent?.name }}
                </mat-option>
                </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('parent','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="button" (click)="submit()">{{'translations.save' | translate}}</button>
        <button class="btn" type="button" (click)="addEditUnitModal.hideModal()">{{'translations.cancel' | translate}}</button>
    </div>
</form>
</fap-modal>

<fap-modal #addEditPartyModal [showFooter]="false"
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event)"
    (updatedParty)="addedOrUpdatedParty($event)"
    (cancel)="addEditPartyModal.hideModal()"
    >
    </fap-add-edit-party>
</fap-modal>

<fap-modal #addEditProductModal
[inputTitle]="'product.addEditProduct' | translate" (outputOnHide)="productId = null">
        <fap-add-edit-product 
            [productId]="productId"
            (closeModal)="addEditProductModal.hideModal()"
            (addedProduct)="addProductAction($event);addEditProductModal.hideModal()"
            (updatedProduct)="updateProductAction($event);addEditProductModal.hideModal()">
        </fap-add-edit-product>
</fap-modal>

<fap-modal #addEditProductionProductModal
[inputTitle]="'product.addEditProductionProduct' | translate" (outputOnHide)="productId = null">
        <fap-add-edit-product 
            [productId]="productId"
            [produced]="true"
            [from]="true"
            (closeModal)="addEditProductionProductModal.hideModal()"
            (addedProduct)="addProductionProductAction($event);addEditProductionProductModal.hideModal()"
            (updatedProduct)="updateProductionProductAction($event);addEditProductionProductModal.hideModal()">
        </fap-add-edit-product>
</fap-modal>

<fap-modal #productsModal [inputIsLarge]="true"
[inputTitle]="'stock.products' | translate" [showFooter]="false">
    <products-modal (hideModal)="hideProducts()" (selectedProduct)="assignProduct($event)" [produced]="produced" [show]="show"></products-modal>
</fap-modal>