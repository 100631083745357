<div class="d-flex activities-card" *ngIf="activity" (click)="selectActivity.emit(activity?.id)">
    <span *ngFor="let type of activityTypes">
        <div
            class="abs_child"
            [style]="{ background: type?.color }"
            *ngIf="type?.id == activity.type"
        ></div>
    </span>
    <div class="split d-flex">
        <div class="left_part">
            <div class="bg-infos">
                <span *ngFor="let type of activityTypes">
                    <span
                        class="type_color typebg"
                        [style]="{ background: type?.color }"
                        *ngIf="type?.id == activity.type"
                    ></span>
                </span>
            </div>
            <div class="ppl">
                <ng-container *ngFor="let person of persons">
                    <div class="user user_im img_place_bg assigned" *ngIf="person.id === activity.assignedTo">
                        <img
                            src="../../../../../../assets/images/people_placeholder.svg"
                            alt="assigned to"
                            *ngIf="person.picture == null"
                        />
                        <img
                            [src]="mediaUrl + person.picture"
                            alt="assigned to"
                            *ngIf="person.picture != null"
                        />
                    </div>
                </ng-container>
                <div class="user user_im img_place_bg" *ngIf="activity.assignedTo == null">
                    <img
                        src="../../../../../../assets/images/people_placeholder.svg"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div class="mid_part">
            <div class="title_row">
                <div class="edit_link p-2 title">
                    <h5>{{ activity?.name ? activity?.name : 'No name' }}</h5>
                    <!-- <p class="select-option-info">{{ activity.info }}</p> -->
                    <!-- <p class="m-0">
                        <ng-container *ngFor="let plan of globalRegistry?.systemData?.plans">
                            <ng-container *ngIf="plan?.id === activity?.plan">
                                <span class="select-option-info lots">{{ [globalRegistry.systemData.lots, plan?.lots] | getLotNames }}</span>
                            </ng-container>
                        </ng-container>
                    </p> -->
                    <p class="m-0">
                        <ng-container *ngFor="let plan of globalRegistry?.systemData?.plans">
                            <ng-container *ngIf="plan?.id === activity?.plan">
                                {{getTranslation(plan?.nameT)}}
                                <!-- <ng-container *ngFor="let type of planTypes">
                                    <ng-container *ngIf="type?.id === plan?.type">
                                        <span class="select-option-info lots" *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                        <span class="select-option-info lots" *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                    </ng-container>
                                </ng-container> -->
                            </ng-container>
                        </ng-container>
                      </p>
                </div>
            </div>
            <div class="sub_contents">
                <div class="c1">
                    <span>
                        <p class="pl-2 mb-0">
                            <ng-container *ngFor="let type of activityTypes">
                                <span *ngIf="type.id == activity.type">
                                    <span *ngIf="type?.nameT">
                                        <span *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                        <span *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                    </span>
                                    <span *ngIf="!type?.nameT">{{type.name}}</span>
                                </span>
                            </ng-container>
                          </p>
                      </span>
                </div>
                <div class="c2">
                    <div class="pl-2 pb-2 act_date">
                        <p class="select-option-date">
                            {{ activity?.started | date: "MMM dd yyyy, HH:mm" }}
                        </p>
                    </div>
                    <div class="pl-2 act_date">
                        <p class="select-option-date">
                            {{ activity?.ended | date: "MMM dd yyyy, HH:mm" }}
                        </p>
                    </div>
                </div>
                <!-- <div class="c3 pc">
                    <div class="act_area">
                        <p class="p-1 mb-0 select-option-time">
                            <img src="../../../../../../assets/images/clock.png" alt=""> {{ calcTime(activity?.workingTime) }} min</p>
                        <p class="p-1 select-option-time">
                            <img src="../../../../../../assets/images/area.png" alt=""/> {{ activity.area }} ha</p>
                        <p class="pl-1 pt-1 mb-0 select-option-time"><img
                            src="../../../../../../assets/images/distance.png" alt=""/> {{ activity.distance }} km</p>
                    </div>
                </div> -->
            </div>
    </div>
    <div class="right_part">
            <div
                *ngIf="activity.attachedEquipments.length == 0"
                class="empty-equipment d-flex eqs"
            >
                <!-- <div class="d-flex border border-black ml-1 eq used">
                    <div class="p-0 activitycard-implementinfo"></div>
                    <div class="p-0">
                        <span class="p-0 user_im img_place_bg lands">
                            <img style="height: 50%; width: 80%;" src="../../../../../../assets/images/landscape_placeholder.png" alt="placeholder">
                        </span>
                    </div>
                </div>
                <div class="d-flex border border-black ml-1 eq">
                    <div class="p-0 activitycard-implementinfo"></div>
                    <div class="p-0">
                        <span class="p-0 user_im img_place_bg lands">
                            <img style="height: 50%; width: 80%;" src="../../../../../../assets/images/landscape_placeholder.png" alt="placeholder">
                        </span>
                    </div>
                </div>
                <div class="d-flex border border-black ml-1 eq">
                    <div class="p-0 activitycard-implementinfo"></div>
                    <div class="p-0">
                        <span class="p-0 user_im img_place_bg lands">
                            <img style="height: 50%; width: 80%;" src="../../../../../../assets/images/landscape_placeholder.png" alt="placeholder">
                        </span>
                    </div>
                </div> -->
            </div>
            <div
                *ngIf="activity.attachedEquipments.length != 0"
                class="d-flex eqs"
            >
                <ng-container *ngFor="let eq of activity.attachedEquipments">
                    <div
                        *ngIf="eq.attachable == 0"
                        class="d-flex border border-black ml-1 eq used"
                        style="order: 1"
                    >
                        <div class="p-0 activitycard-implementinfo">
                            {{ eq?.registrationPlate }}
                        </div>
                        <div class="p-0 user_im img_place_bg lands">
                            <!-- <img [src]="eq?.product?.name" alt="" /> -->
                            
                                <div class="user_im img_place_bg lands" *ngIf="eq?.product?.images.length">
                                    <img [src]="mediaUrl + eq?.product?.images[eq?.product?.images.length - 1].file" alt="">
                                </div>
                            
                            <img src="../../../../../../assets/images/image_placeholder.png" 
                            *ngIf="eq.product.images.length == 0" 
                            alt="">
                        </div>
                    </div>
                    <div
                        *ngIf="eq.attachable == 1"
                        class="d-flex border border-black ml-1 eq"
                        style="order: 2"
                    >
                        <div class="p-0 activitycard-implementinfo">
                            {{ eq?.registrationPlate }}
                        </div>
                        <div class="p-0 user_im img_place_bg lands">
                            <!-- <img [src]="eq?.product?.name" alt="" /> -->
                            <div class="user_im img_place_bg lands" *ngIf="eq?.product?.images.length">
                                <img [src]="mediaUrl + eq?.product?.images[eq?.product?.images.length - 1].file" alt="">
                            </div>
                            <img src="../../../../../../assets/images/image_placeholder.png" 
                            *ngIf="eq.product.images.length == 0" 
                            alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="mobl">
        <p class="mb-0 select-option-time">
            <img src="../../../../../../assets/images/area.png" alt=""/> {{ activity.area }} ha</p>
        <p class="pl-2 mb-0 select-option-time"><img
            src="../../../../../../assets/images/distance.png" alt=""/> {{ activity.distance }} km</p>
        <p class="pl-2 mb-0 select-option-time">
            <img src="../../../../../../assets/images/clock.png" alt=""> {{ calcTime(activity?.workingTime) }} min</p>
    </div>
</div>
